import { SharedService } from './../../shared/shared.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/shared/token-storage.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private tokenStorage: TokenStorageService, private router: Router, private route: ActivatedRoute,private sharedService:SharedService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authTok = req;


        let sourceUrl = window.location.href;

        if ( ! (sourceUrl.indexOf("updatePassword") != -1)) {
            if(authTok.url.indexOf("environment") != -1 || authTok.url.indexOf("version") != -1){
                authTok = req.clone({
                    setHeaders: {
                        Authorization: ""
                    }
                });
            } else {
                if (this.tokenStorage.getToken() != null ) {
                    authTok = req.clone({
                        setHeaders: {
                            Authorization: this.tokenStorage.getToken()
                        }
                    });
                }else {
                    this.router.navigate(['/login']);
                }
            }



        }


        return next.handle(authTok).do((event: HttpEvent<any>) => {
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                // executes only if error status code is 401 and 403
               // if (err.status === 401 || err.status === 403) {
                    // this.sharedService.showToastrErrorMsg('Session Expired.Please login to continue.');
                  //  this.router.navigate(['/login']);
                //} /* else if (err.status === 403) {
                   // this.router.navigate(['/login']);
                //} */
            } /* else if () {
                //page not found
            } */
        })

    }

}
