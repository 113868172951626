import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  httpHeader;
  loginCache;
  _userEmail;
  getUserListUrl: string = "/admin/user/list";
  getUserListWrapperUrl: string = "/admin/user/buUsers";
  saveUserUrl: string = "/admin/user/save";
  editUserUrl: string = "/admin/user/edit";
  userInitUrl: string = "/admin/user/init";
  getTerritorySearcListUrl: string = "/admin/territory/territorySearch";
  getTerritoryListUrl: string = "/admin/territory/territorylist";
  changeUserStatusUrl: string = "/admin/user/updateUserStatus";
  changeCMSUserStatusUrl: string = "/admin/user/updateCMSUserStatus";
  deleteUserUrl: string = "/admin/user/delete";
  uploadTemplateUrl: string = "/admin/user/upload";
  downloadTemplateUrl: string = "/admin/user/downloadTemplate/";
  viewProfileUrl: string = "/admin/user/profile/";
  getTimeZoneUrl: string = "./assets/timeZones.json";
  getExportUrl: string = "/admin/user/userExport";
  shipToListUrl :string = "/getShipTo";
  getUserBuListUrl: string = "/admin/user/userBuList";

  constructor(public httpClient: HttpClient, public sharedService: SharedService,public tokenStorage: TokenStorageService) { }

  viewUsersList() {
    let _url = this.sharedService._hostUrl + this.getUserListWrapperUrl;
    return this.httpClient.get(_url);
  }

  deleteUser(body) {
    let _url = this.sharedService._hostUrl + this.deleteUserUrl;
    return this.httpClient.post(_url, body, { headers: this.httpHeader, responseType: 'text' });
  }

  saveUser(body) {
    let _url = this.sharedService._hostUrl + this.saveUserUrl;
    return this.httpClient.post(_url, body, { headers: this.httpHeader });
  }

  editUser(body) {
    let _url = this.sharedService._hostUrl + this.editUserUrl;
    return this.httpClient.post(_url, body, { headers: this.httpHeader });
  }

  viewProfilePage(body,admin) {
    let _url = this.sharedService._hostUrl + this.viewProfileUrl + admin;
    return this.httpClient.post(_url, body);
  }

  uploadTemplate(formData) {
    let _url = this.sharedService._hostUrl + this.uploadTemplateUrl;
    return this.httpClient.post(_url, formData, { responseType: "blob", headers: this.httpHeader });
  }

  userInit(body) {
    let _url = this.sharedService._hostUrl + this.userInitUrl;
    return this.httpClient.post(_url, body, { headers: this.httpHeader });
  }

  getTerritorySearchData(body) {
    let _url = this.sharedService._hostUrl + this.getTerritorySearcListUrl;
    return this.httpClient.post(_url, body,{ headers: this.httpHeader });
  }
  getTerritoryList() {
    let _url = this.sharedService._hostUrl + this.getTerritoryListUrl;
    return this.httpClient.get(_url, { headers: this.httpHeader });
  }

  changeUserStatus(body,isNmbs) {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    let _url = this.sharedService._hostUrl + this.changeUserStatusUrl + isNmbs;
    let httpHeaders = new HttpHeaders({ 'communityId': this.loginCache.communityId[0].toString(), 'userId': this.loginCache.userId.toString() });
    return this.httpClient.post(_url, body, { headers: httpHeaders });
  }

  changeCMSUserStatus(body) {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    let _url = this.sharedService._hostUrl + this.changeCMSUserStatusUrl;
    let httpHeaders = new HttpHeaders({ 'communityId': this.loginCache.communityId[0].toString(), 'userId': this.loginCache.userId.toString() });
    return this.httpClient.post(_url, body, { headers: httpHeaders });
  }

  getExcelTemplate(file,communityId) {
    let _url = this.sharedService._hostUrl + this.downloadTemplateUrl + communityId;
    let body = { filename: file };
    return this.httpClient.post(_url, body, { responseType: "blob", headers: this.httpHeader });

  }
  getTimeZone() {
    return this.httpClient.get(this.getTimeZoneUrl);

  }
  getUserExport() {
    let _url = this.sharedService._hostUrl + this.getExportUrl;
    return this.httpClient.get(_url, { headers: this.httpHeader });

  }
  getExternalIdByBuId(communityId, buId) {
    let _url = this.sharedService._hostUrl + "/getExternalId/" + communityId.toString() + "/" + buId.toString();
    return this.httpClient.get(_url);
  }

  fetchShipToList(buId){
    let url = this.sharedService._hostUrl + this.shipToListUrl;
    let httpHeader1 = new HttpHeaders({ 'buId': buId});
    return this.httpClient.get(url,{ headers: httpHeader1 });
  }
  getUserBuList(body) {
    let url = this.sharedService._hostUrl + this.getUserBuListUrl;
    return this.httpClient.post(url,body,{headers : this.httpHeader})
  }
}
