import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ChangePasswordService } from './change-password.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../shared/token-storage.service';
import { PasswordPolicy } from '../../configuration/password-policy/password-policy.model';
import { SharedService } from '../../shared/shared.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('f')
  public form: NgForm;

  @Output() EventEmitter = new EventEmitter();
  url: string;

  public oldPassword: string;
  public newPassword: string;
  public confirmPassword: string;


  public submitAttempt = false;
  public formValid = false;
  public error;
  public pwdPolicyMismatch = false;
  public oldNewPwdSameError = false;
  public newPwdMatchError = false;
  public serverSideError = false;
  isFirstTimeLogin: boolean;
  showHome: boolean = false;
  compliancePageDetail;
  show_Header: boolean = false;
  message;
  line1;
  line2;
  line3;
  line4;
  line5;
  line6;
  permissions: any = [];
  loginCache;
  policy: any = [];
  passwordPolicyList: PasswordPolicy[] = [];
  passwordHistoryCount;
  public locale: string;

  constructor(public translate: TranslateService, public changePasswordService: ChangePasswordService, public router: Router,
    public tokenstorage: TokenStorageService, public sharedService: SharedService) {

  }

  ngOnInit() {

    this.changePasswordService.getPolicyValues().subscribe((data: PasswordPolicy[]) => {

      this.passwordPolicyList = data;
      this.passwordPolicyList.forEach(passwordPolicy => {

        if (passwordPolicy.policyName == "password_min_len") {
          this.message = this.translate.instant('password.policy.new2');
            this.line1 = this.message.replace("{0}", passwordPolicy.policyValue);
        }
        if (passwordPolicy.policyName == 'password_special_character') {
          this.message = this.translate.instant('password.policy.new3');
          this.line2 = this.message.replace("{0}", passwordPolicy.policyValue);

        }
        if (passwordPolicy.policyName == 'password_enforce_uppercase') {
          this.line2 = this.line2.replace("{1}", passwordPolicy.policyValue);
        }

        if (passwordPolicy.policyName == 'password_history_count') {
          this.message = this.translate.instant('password.policy.new4');
          this.line3 = this.message.replace("{0}", passwordPolicy.policyValue);
          this.passwordHistoryCount = passwordPolicy.policyValue;
        }

        if (passwordPolicy.policyName == 'password_change_freq') {
          this.message = this.translate.instant('password.policy.new5');
          this.line4 = this.message.replace("{0}", passwordPolicy.policyValue);
        }
        if (passwordPolicy.policyName == 'password_unsucessful_login') {
          this.message = this.translate.instant('password.policy.new6');
          this.line5 = this.message.replace("{0}", passwordPolicy.policyValue);
        }
      });
      this.line6 = this.translate.instant('password.special.characters.allowed');
    });

    this.loginCache = JSON.parse(this.tokenstorage.getLoginCache());
    this.compliancePageDetail = JSON.parse(sessionStorage.getItem("compliancePageDetail"));
    if (JSON.parse(sessionStorage.getItem("Url")) == "/changePassword") {
      this.show_Header = true;
    }


    let lang = this.loginCache.userLocale;

    if (lang == "pt") {
      this.onChangeLocale("pt-br");
    }
    else if (lang == "cn") {
      this.onChangeLocale("zh-cn")
    }
    else {
      this.onChangeLocale(lang)
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang != null) {
        if (event.lang == "pt")
          this.onChangeLocale("pt-br")
        else if (event.lang == "cn")
          this.onChangeLocale("zh-cn")
        else
          this.onChangeLocale(event.lang)
      }
    })

  }

  onChangeLocale(locale: string) {
    this.locale = locale;
    this.passwordPolicyList.forEach(passwordPolicy => {

      if (passwordPolicy.policyName == "password_min_len") {
        this.message = this.translate.instant('password.policy.new2');
        this.line1 = this.message.replace("{0}", passwordPolicy.policyValue);
      }
      if (passwordPolicy.policyName == 'password_special_character') {
        this.message = this.translate.instant('password.policy.new3');
        this.line2 = this.message.replace("{0}", passwordPolicy.policyValue);

      }
      if (passwordPolicy.policyName == 'password_enforce_uppercase') {
        this.line2 = this.line2.replace("{1}", passwordPolicy.policyValue);
      }

      if (passwordPolicy.policyName == 'password_history_count') {
        this.message = this.translate.instant('password.policy.new4');
        this.line3 = this.message.replace("{0}", passwordPolicy.policyValue);
        this.passwordHistoryCount = passwordPolicy.policyValue;
      }

      if (passwordPolicy.policyName == 'password_change_freq') {
        this.message = this.translate.instant('password.policy.new5');
        this.line4 = this.message.replace("{0}", passwordPolicy.policyValue);
      }
      if (passwordPolicy.policyName == 'password_unsucessful_login') {
        this.message = this.translate.instant('password.policy.new6');
        this.line5 = this.message.replace("{0}", passwordPolicy.policyValue);
      }
    });
    this.line6 = this.translate.instant('password.special.characters.allowed');
  }


  onSubmit() {
    this.changePasswordService.changePassword(this.oldPassword, this.newPassword).subscribe(data => {
      if (data["errorMessage"] == "changepassword.previous") {
        this.message = this.translate.instant('changepassword.previous');
        this.error = this.message.replace("{0}", this.passwordHistoryCount);
      } else {
        this.error = data["errorMessage"];
      }
      if (this.error == null || this.error == undefined) {
        alert(this.sharedService.translate('password.change.successfull'));
        if ((JSON.parse(sessionStorage.getItem("Url"))) == "/changePassword") {
          this.showHome = true;
        }
        if (!this.compliancePageDetail.profileSetup) {
          sessionStorage.setItem("mustchangePW", "false");
          this.router.navigate(['/home']);
        }

        else {
          this.compliancePageDetail.passwordExpiry = false;
          this.compliancePageDetail.oneTimePwd = false;
          sessionStorage.setItem("changePassword", JSON.stringify(true));
          sessionStorage.setItem("compliancePageDetail", JSON.stringify(this.compliancePageDetail));
          this.router.navigate(['/compliancePolicy'])
        }
        // this.router.navigate(['/home']);
      }
      else {
        this.serverSideError = true;
      }
      if (this.showHome) {
        this.router.navigate(['/home'])
      }
    },
      (error) => {
        alert(error);

      }
    );
  }


  cancel() {
    let mustChangePw = sessionStorage.getItem("mustchangePW");
    if (JSON.parse(sessionStorage.getItem("Url")) == "/changePassword") {
      this.router.navigate(['/home']);
    }
    else {
      this.router.navigate(['/login']);
    }


  }

  validatePassword() {
    this.formValid = true;
    if (this.form.valid) {
      this.formValid = true;
    } else {
      this.formValid = false;
    }
    if (this.oldPassword == this.newPassword && this.oldPassword != undefined && this.newPassword != undefined && this.oldPassword != null && this.newPassword != null) {
      this.formValid = false;
      this.oldNewPwdSameError = true;
    } else {
      this.oldNewPwdSameError = false;
    }

    if (this.newPassword != this.confirmPassword) {
      this.formValid = false;
      this.newPwdMatchError = true;
    } else {
      this.newPwdMatchError = false;
    }
    this.submitAttempt = true;
  }



}
