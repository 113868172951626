import { LoaderService } from './../../shared/loader.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../shared/token-storage.service';
import { ComplianceService } from './compliance.service';
import { SharedService } from '../../shared/shared.service';
@Component({
  selector: 'app-compliance-policy',
  templateUrl: './compliance-policy.component.html',
  styleUrls: ['./compliance-policy.component.css']
})
export class CompliancePolicyComponent implements OnInit {

  compliancePageDetail;
  compliance: boolean = true;
  compliance1: boolean = true;
  permissions: any = [];
  loginCache;
  userData;
  constructor(public router: Router, public complianceService: ComplianceService,
    public tokenStorage: TokenStorageService, public sharedService: SharedService, public loader: LoaderService) { }

  ngOnInit() {
    this.compliance = true;
    this.compliance1 = false;
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    this.userData = this.loginCache.userPermission;
    //(this.userData.some(perm => perm === 'ROLE_PERMISSION_EditInvoice')) ? (this.sharedService.isNmbs = true) : (this.sharedService.isNmbs = false);
    if (JSON.parse(sessionStorage.getItem("compliancePageDetail"))) {
      this.compliancePageDetail = JSON.parse(sessionStorage.getItem("compliancePageDetail"));
      if (this.compliancePageDetail.compliance == "NO")
        this.compliance = true;
      else if (this.compliancePageDetail['compliance'].toLowerCase() == "yes") {

          if (this.compliancePageDetail.passwordExpiry || this.compliancePageDetail.oneTimePwd) {
            this.compliance = false;
            this.router.navigate(['/changePasswordPage']);
          } else if (this.compliancePageDetail.profileSetup) {
            this.compliance = false;
            this.compliance1 = true;
            this.router.navigate(['/profileSetup']);
          }
          // else this.router.navigate(['/home']);

      }
    }

  }
  test() {
    if (JSON.parse(sessionStorage.getItem("compliancePageDetail"))) {
      this.compliancePageDetail = JSON.parse(sessionStorage.getItem("compliancePageDetail"));
      if (this.compliancePageDetail.compliance == "NO")
        this.compliance = true;
      else if (this.compliancePageDetail['compliance'].toLowerCase() == "yes") {

          if (this.compliancePageDetail.passwordExpiry || this.compliancePageDetail.oneTimePwd) {
            this.compliance = false;
            this.router.navigate(['/changePasswordPage']);
          } else if (this.compliancePageDetail.profileSetup) {
            this.compliance = false;
            this.compliance1 = true;
            this.router.navigate(['/profileSetup']);
          }

        // else this.router.navigate(['/home']);
      }

    }
  }

  agree(): void {
    this.compliance = false;
    this.compliancePageDetail.compliance = "YES";
    sessionStorage.setItem("compliancePageDetail", JSON.stringify(this.compliancePageDetail));
    this.loader.display(true);
    this.complianceService.complianceAgree().subscribe(data => {
      let showHome = false;
      if (data) {
        this.compliance = false;

          if (this.compliancePageDetail.passwordExpiry || this.compliancePageDetail.oneTimePwd) {
            this.compliance = false;
            this.loader.display(false);
            this.test();
            showHome = false;
            sessionStorage.setItem("mustchangePW", "true");
            this.router.navigate(['/changePasswordPage']);
          }
          else if (this.compliancePageDetail.profileSetup) {
            this.compliance = false;
            this.compliance1 = true;
            showHome = false;
            this.router.navigate(['/profileSetup'])
          }
          else {
            showHome = true;
          }

      }
      if (showHome) {
        this.router.navigate(['/home']);
      }
    },
      error => {
        alert("something went wrong");
        this.router.navigate(['/login']);

      }
    )

  }

  disagree(): void {
    this.router.navigate(['/login']);
  }
}
