import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/shared/home.service';
import { LoginService } from 'src/app/auth/login/login.service';
import { SharedService } from 'src/app/shared/shared.service';
import { CommunityHierarcyService } from 'src/app/community/community-hierarcy.service';
import { CustomerService } from 'src/app/orders/customer-list/customer.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  url: string;
  userPermissions: any = [];
  isAdmin: boolean = false;
  isSupportAdmin: boolean = false;
  isCustomer: boolean = false;
  isterri: boolean = false;
  isGlobalAdmin: boolean = false;
  @Output() sideMenuEventEmitter = new EventEmitter();
  permissions: any = [];
  enableProductCatalog: boolean = false;
  homeCache: any = {};
  loginCache;
  supplierData: any[] = null;
  unreadCount;
  isAdminWithOrders: boolean = false;
  communityValue;
  levelNumber: number;
  userData;
  isCreateOrderEnable: boolean = false;
  isMultipleCustomer: boolean = false;
  loadingFlag: boolean = false;
  textMessage;
  successCheck: boolean = false;
  errorCheck;
  globalAndZoneAdminCheck: boolean = false;


  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public customerService: CustomerService,
    public sharedService: SharedService,
    public baseLayout: BaseLayoutComponent,
    public router: Router,
    public tokenStorage: TokenStorageService,
    public homeservice: HomeService,
    public loginService: LoginService,
    public communityHierarcyService: CommunityHierarcyService
  ) {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
  }

  ngOnInit() {
    this.userData = this.loginCache.userPermission;

  }

  viewHome(): void {
    this.url = "/home";
    this.sharedService.breadCrumbsArray = [];
    this.sharedService.showSearchBarAndCart = false;

    $(".left_menu_options").addClass("hidden");
    this.sideMenuEventEmitter.emit({ url: this.url });
    //this.sharedService.showmenu = false;
  }



  viewUsersList(): void {
    this.sharedService.showSearchBarAndCart = false;
    this.clearDataTable();
    this.baseLayout.pgName = '';
    this.url = "/user/userList";

    /* logic for hidding breadcrumbs in other pages */
    this.sharedService.breadCrumbsArray = [];
    setTimeout(time => {
      if (!($(".left_menu_options").hasClass("hidden"))) {
        $('.breadcrumbs_toggle').trigger('click');
        $(".left_menu_options").addClass("hidden");
      }
    }, 500);

    this.sideMenuEventEmitter.emit({ url: this.url });
  }

  viewPasswordPolicy(): void {
    this.sharedService.showSearchBarAndCart = false;
    this.baseLayout.pgName = '';
    this.url = "/config/passwordPolicy";

    /* logic for hidding breadcrumbs in other pages */
    this.sharedService.breadCrumbsArray = [];
    setTimeout(time => {
      if (!($(".left_menu_options").hasClass("hidden"))) {
        $('.breadcrumbs_toggle').trigger('click');
        $(".left_menu_options").addClass("hidden");
      }
    }, 500);

    this.sideMenuEventEmitter.emit({ url: this.url });
  }

  viewTerritoryManagement(): void {
    this.sharedService.showSearchBarAndCart = false;
    this.clearDataTable();
    this.baseLayout.pgName = '';
    this.url = "/territory/ware-house/shops";

    /* logic for hidding breadcrumbs in other pages */
    this.sharedService.breadCrumbsArray = [];
    setTimeout(time => {
      if (!($(".left_menu_options").hasClass("hidden"))) {
        $('.breadcrumbs_toggle').trigger('click');
        $(".left_menu_options").addClass("hidden");
      }
    }, 500);

    this.sideMenuEventEmitter.emit({ url: this.url });
  }

  viewProductCatalog(): void {
    this.sharedService.showSearchBarAndCart = false;

    this.baseLayout.pgName = '';
    this.url = "/products/productCatalog";

    /* logic for hidding breadcrumbs in other pages */
    this.sharedService.breadCrumbsArray = [];
    setTimeout(time => {
      if (!($(".left_menu_options").hasClass("hidden"))) {
        $('.breadcrumbs_toggle').trigger('click');
        $(".left_menu_options").addClass("hidden");
      }
    }, 500);

    this.sideMenuEventEmitter.emit({ url: this.url });
  }

  viewProductCatalogDownload(): void {
    this.sharedService.showSearchBarAndCart = false;

    this.baseLayout.pgName = '';
    this.url = "/products/productCatalogDownload";

    /* logic for hidding breadcrumbs in other pages */
    this.sharedService.breadCrumbsArray = [];
    setTimeout(time => {
      if (!($(".left_menu_options").hasClass("hidden"))) {
        $('.breadcrumbs_toggle').trigger('click');
        $(".left_menu_options").addClass("hidden");
      }
    }, 500);

    this.sideMenuEventEmitter.emit({ url: this.url });
  }



  createOrder(): void {
    this.baseLayout.pgName = '';
    this.url = "/orders/createOrder";
    this.sideMenuEventEmitter.emit({ url: this.url });
  }


  clearDataTable(): void {
    sessionStorage.removeItem('searchVal');
    sessionStorage.removeItem('searchHomePage');
    sessionStorage.removeItem('searchCustomerList');
    sessionStorage.removeItem('searchOderList');
    sessionStorage.removeItem('searchOderDetail');
  }

  closeSideMenu() {
    //this.sharedService.showmenu = false;
  }



  createProductCtatlog() {
    this.sharedService.showSearchBarAndCart = false;
      this.url ="ghaama/uploadProduct";

    /* logic for hidding breadcrumbs in other pages */
    this.sharedService.breadCrumbsArray = [];
    setTimeout(time => {
      if (!($(".left_menu_options").hasClass("hidden"))) {
        $('.breadcrumbs_toggle').trigger('click');
        $(".left_menu_options").addClass("hidden");
      }
    }, 500);

    this.sideMenuEventEmitter.emit({ url: this.url });
  }

  editPassword() {
    this.sharedService.showSearchBarAndCart = false;
    this.url = "/config/editPassword";

    /* logic for hidding breadcrumbs in other pages */
    this.sharedService.breadCrumbsArray = [];
    setTimeout(time => {
      if (!($(".left_menu_options").hasClass("hidden"))) {
        $('.breadcrumbs_toggle').trigger('click');
        $(".left_menu_options").addClass("hidden");
      }
    }, 500);

    this.sideMenuEventEmitter.emit({ url: this.url });
  }



  goToProductSearch() {
    this.sharedService.showSearchBarAndCart = false;
    this.url = "/products/productSearch";
    if (this.router.url == this.url)
      this.url = "/products/product-Search"
    this.router.navigate([this.url]);
  }



  viewProduct() {
    this.sharedService.showSearchBarAndCart = false;
    this.url = "/ghaama/viewProductList";
    this.router.navigate([this.url]);
  }

  viewProductApproval(){
    this.sharedService.showSearchBarAndCart = false;
    this.url = "/ghaama/viewProductApproval";
    this.router.navigate([this.url]);
  }

  viewCustomerList(): void {
    this.baseLayout.pgName = '';
    this.url = "/orders/customerList";
    this.router.navigate([this.url]);
    //this.sideMenuEventEmitter.emit({ url: this.url });
  }

  viewOrderList(): void {
    this.baseLayout.pgName = '';
    this.url = "/orders/orderList";
    this.router.navigate([this.url]);
    //this.sideMenuEventEmitter.emit({ url: this.url });
  }

  viewAccountDetails(){
    this.sharedService.showSearchBarAndCart = false;
    this.url = "/ghaama/accountDetails";
    this.router.navigate([this.url]);
  }

  viewTransactionHistory(){
    this.sharedService.showSearchBarAndCart = false;
    this.url = "/ghaama/transactionHistory";
    this.router.navigate([this.url]);
  }

  hasPermission(permission: string): boolean {
   return this.userData.includes(permission);
  }
}
