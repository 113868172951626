import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { FusionChartsModule } from 'angular-fusioncharts';
import { GoogleChartsModule } from 'angular-google-charts';
import { TreeComponent, TreeModule } from 'angular-tree-component';
//import * as FusionCharts from 'fusioncharts';
//import * as Charts from 'fusioncharts/fusioncharts.charts';
//import * as Powercharts from 'fusioncharts/fusioncharts.powercharts';
//import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { TreeviewModule } from 'ngx-treeview';
import { BaseLayoutComponent } from 'src/app/core/base-layout/base-layout.component';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { AppRoutingModule } from './/app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ComplianceService } from './auth/compliance-policy/compliance.service';
import { Interceptor } from './auth/interceptors/app.interceptor';
import { LoginService } from './auth/login/login.service';
import { HeaderComponent } from './core/header/header.component';
import { SideMenuComponent } from './core/side-menu/side-menu.component';
import { NewHomePageComponent } from './new-home-page/new-home-page.component';

import { CustomerService } from './orders/customer-list/customer.service';
import { OrderNotificationComponent } from './orders/order-list/order-notification/order-notification.component';
import { HomeService } from './shared/home.service';
import { SharedModule } from './shared/shared.module';
import { TerritoryManagmentService } from './territory/territory-managment.service';
import { ToastrModule } from 'ngx-toastr';

import { NgxSpinnerModule } from 'ngx-spinner';
import {FormsModule} from '@angular/forms';
import {DpDatePickerModule} from 'ng2-date-picker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LandingPageComponent } from './landing/landing-page/landing-page.component';
import { UploadProductsComponent } from './ghaama/Manage Products/upload-products/upload-products.component';
//import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import {PopoverModule} from "ngx-popover";
//FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme, Powercharts);
import {MatTreeNestedDataSource, MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ColorPickerModule } from 'ngx-color-picker';

export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}




@NgModule({
  declarations: [
    AppComponent,
    NewHomePageComponent,
    OrderNotificationComponent,
    SideMenuComponent,
    HeaderComponent,
    BaseLayoutComponent,
    LandingPageComponent,

  ],
  imports: [
    PopoverModule,
    GoogleChartsModule,
    TreeModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
   // FusionChartsModule,
    OwlDateTimeModule,
     OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    NgxSmartModalModule.forRoot(),
    SharedModule,
    AuthModule,
    FormsModule,
    NgxSpinnerModule,
    DpDatePickerModule,
    ColorPickerModule,

    //SocialLoginModule,
    TreeviewModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      newestOnTop : true
    }),

  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true
  },
  /* {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '363919311329-eutpkqhs81t9un7sullitu03ri6vrq9n.apps.googleusercontent.com'
          )
        }
      ],
      onError: (err) => {
        console.error(err);
      }
    } as SocialAuthServiceConfig,
  }, */

  HomeService, LoginService, NgxSmartModalService,
  TerritoryManagmentService, CustomerService, ComplianceService, TokenStorageService,
{ provide: APP_BASE_HREF, useValue: '/ghaama/' }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
