import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './auth.guard';
import { SharedModule } from '../shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CompliancePolicyComponent } from './compliance-policy/compliance-policy.component';
import { ProfileSetupComponent } from './profile-setup/profile-setup.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SelectCommunityComponent } from './select-community/select-community.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    LoginComponent,
    ChangePasswordComponent,
    CompliancePolicyComponent,
    ProfileSetupComponent,
    RedirectComponent,
    SelectCommunityComponent
  ],
  providers: [
    AuthGuard
  ],
  exports:[RedirectComponent]
})
export class AuthModule { }
