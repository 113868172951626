import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../shared/token-storage.service';
import { ComplianceService } from '../compliance-policy/compliance.service';
import { UserService } from '../../user/user-list/user.service';
import { SharedService } from '../../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-setup',
  templateUrl: './profile-setup.component.html',
  styleUrls: ['./profile-setup.component.css']
})
export class ProfileSetupComponent implements OnInit {

  preferredLanguages: any = [];
  compliancePageDetail;
  timeZones;
  firstName;
  middleName;
  lastName;
  selectedTimeZone:any;
  selectedLanguage:any;
  loginCache;
  permissions:any = []
  isNestradeEnglishUser: boolean = false;


  constructor(public router:Router, public service:ComplianceService, public compliance: ComplianceService,public userservice:UserService,
    public sharedService:SharedService,public tokenstorage:TokenStorageService, public translate: TranslateService) { }

  ngOnInit() {
    this.loginCache = JSON.parse(this.tokenstorage.getLoginCache());
    this.compliancePageDetail = JSON.parse(sessionStorage.getItem("compliancePageDetail"));
    this.compliance.getLanguage().subscribe(data =>{
      this.preferredLanguages = data;
      this.prepopulateData();
    })

    this.userservice.getTimeZone().subscribe(data=>{
      this.timeZones = data;
    })
    
   
  }

  prepopulateData() {
    this.firstName = this.loginCache['userFirstName'];
    this.middleName = this.loginCache['userMiddleName'];
    this.lastName = this.loginCache['userLastName'];
    this.selectedTimeZone = this.loginCache['userTimeZone'];
    this.selectedLanguage = this.loginCache['userLocale'];
    if(this.loginCache["userLocale"] == "xx"){
          this.selectedLanguage = this.loginCache["userLocale"];
          this.isNestradeEnglishUser = true;
        } else {
          this.selectedLanguage = this.loginCache["userLocale"];
          this.preferredLanguages.forEach((element,i) => {
            if(element['value'] == 'xx'){
              this.preferredLanguages.splice(i, 1);
            }
          });

        }
    
  }

  saveUserProfile() {
    let body = {
      "userId": this.loginCache.userId.toString(),
      "userFirstName" : this.firstName,
      "userMiddleName" : this.middleName,
      "userLastName":this.lastName,
      "userTimeZone":this.selectedTimeZone,
      "userLocale":this.selectedLanguage,
      "userEmail":this.loginCache.userEmail
    }
    this.service.saveUserProfile(body).subscribe(data=>{
      if(data){
        if(this.selectedLanguage != this.loginCache['userLocale']){
          let cache =JSON.parse(sessionStorage.getItem("loginCache"));
          cache['userLocale'] = this.selectedLanguage;
          sessionStorage.removeItem("loginCache");
          sessionStorage.setItem("loginCache", JSON.stringify(cache));
          this.loginCache = JSON.parse(this.tokenstorage.getLoginCache());
          this.translate.use(this.loginCache['userLocale']);
        }
        alert(this.sharedService.translate('user.profile.savedsuccessfully'));
        this.compliancePageDetail.profileSetup = true;
        sessionStorage.setItem("compliancePageDetail", JSON.stringify(this.compliancePageDetail));
        sessionStorage.setItem("userFirstname",this.firstName);
        sessionStorage.setItem("userLastName",this.lastName);
        this.router.navigate(['/home']);
      }
    })
  }

  cancelUser(){
    this.router.navigate(['/login']);
  }

}
