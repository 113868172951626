import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './auth.guard';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CompliancePolicyComponent } from './compliance-policy/compliance-policy.component';
import { ProfileSetupComponent } from './profile-setup/profile-setup.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SelectCommunityComponent } from './select-community/select-community.component';
import { SellerChargesComponent } from './seller-charges/seller-charges.component';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    ToastrModule.forRoot({
          positionClass: 'toast-bottom-right',
          newestOnTop : true
        })
  ],
  declarations: [
    LoginComponent,
    ChangePasswordComponent,
    CompliancePolicyComponent,
    ProfileSetupComponent,
    RedirectComponent,
    SelectCommunityComponent,
    SellerChargesComponent
  ],
  providers: [
    AuthGuard
  ],
  exports:[ChangePasswordComponent, CompliancePolicyComponent, ProfileSetupComponent, RedirectComponent, SellerChargesComponent]
})
export class AuthModule { }
