import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input("width") width;
  @Input("display") display;
  @Input("color") color;
  @Input("html") html;
  @Input("htmltitle") htmltitle;
  @Output() event = new EventEmitter();
  @Input() forgotPassword;
  userEmail;
  @Output() enteredEmail = new EventEmitter();
  @ViewChild('f')
  public resetForm :NgForm;
  constructor(public router: Router) { }

  ngOnInit() {
   
  }

  onCloseHandled() {
    this.display = 'none';
    this.event.emit(this.display);
    this.userEmail = undefined;
    this.resetForm.resetForm();
  }

  submitMail() {
    this.enteredEmail.emit(this.userEmail);
    this.userEmail = undefined;
    this.resetForm.resetForm();
  }

}
