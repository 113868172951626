import { Injectable } from '@angular/core';
import { HttpClient} from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataTableService {
  getDataUrl: string = "./assets/userList.json";

  constructor(public  httpClient:  HttpClient) { }

  getData(pageInfo) {
    return this.httpClient.get(this.getDataUrl);
  }

  getSearchData(searchText) {
    return this.httpClient.get(this.getDataUrl);
  }
}
