import { Injectable } from '@angular/core';

const TOKEN_KEY = 'AuthToken';
const LOGIN_CACHE = 'loginCache';

@Injectable({
  providedIn: 'root'
})


export class TokenStorageService {
  constructor() { }


  signOut() {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(LOGIN_CACHE);
    sessionStorage.clear();
  }

  public saveToken(token: string) {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveLoginCache(loginCache: string) {
    sessionStorage.removeItem(LOGIN_CACHE);
    sessionStorage.setItem(LOGIN_CACHE, loginCache);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public getLoginCache(): string {
    return sessionStorage.getItem(LOGIN_CACHE);
  }

  public removeToken(token: string) {
    sessionStorage.removeItem(token);
  }

  public removeLoginCache(loginCache: string) {
    sessionStorage.removeItem(loginCache);
  }

}
