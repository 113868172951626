import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  httpHeader;
  loginCache;
  getProductsUrl: string = "/admin/ordercreate/fetchAllProducts";
  updateProductUrl: string = "/catalog/demo";
  getParentBusinessUnitsList: string = "/admin/territory/init";

  constructor(
    private sharedService: SharedService,
    public httpClient: HttpClient,
    public tokenStorage: TokenStorageService
  ) {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
  }

  saveProducts(body) {
    let _url = this.sharedService._hostUrl + this.updateProductUrl;
    return this.httpClient.post(_url, body);
  }

  getProductsList(body) {
    let _url = this.sharedService._hostUrl + this.getProductsUrl;
    return this.httpClient.post(_url,body);
  }
  loadParentBusinessUnits() {
    let _url = this.sharedService._hostUrl + this.getParentBusinessUnitsList;
    return this.httpClient.get(_url, { headers: this.httpHeader });
  }
  updateProductImage(vmn){
    let _url = this.sharedService._hostUrl + "/admin/ordercreate/updateImageFetch/" + vmn;
    return this.httpClient.get(_url,{ responseType: 'text' });
  }
}
