import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base64 } from 'js-base64';
import { TokenStorageService } from '../../shared/token-storage.service';
import { SharedService } from '../../shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  getPasswordUrl: string = this.sharedService._hostUrl + "/changeUserPassword";
  getPoliciesListUrl: string = this.sharedService._hostUrl + "/policyListForChangePassword";
  parameters;
  httpOptions;
  loginCache;
  constructor(public httpClient: HttpClient, public sharedService: SharedService, public tokenStorage: TokenStorageService) {
  }

  changePassword(oldPassword: string, newPassword: string) {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
      let headers = new HttpHeaders({
      });
      let Obj = new Object({
        'userEmailId': this.loginCache.userEmail,
        'newPassword': Base64.encode(newPassword),
        'oldPassword': Base64.encode(oldPassword)
      });
      return this.httpClient.post(this.getPasswordUrl, Obj, { headers: headers });
  }

  getPolicyValues() {
    return this.httpClient.get(this.getPoliciesListUrl);
  }

  // getCmsPasswordPolicy(companyId){
  //   let url = this.sharedService._hostUrl + "/migration/getCompanyPasswordPolicyCMS/" + companyId;
  //   return this.httpClient.get(url);
  // }

  // cmsChangePassword(body) {
  //   let url = this.sharedService._hostUrl + "/migration/updateUserPasswordCMS";
  //   return this.httpClient.post(url, body);
  // }

  getCmsCompanyId(body){
    let url = this.sharedService._hostUrl + "/migration/searchUserByEmail";
    return this.httpClient.post(url, body);
  }

}
