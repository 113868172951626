import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '../../shared/token-storage.service';
import { SharedService } from '../../shared/shared.service';



@Injectable({
  providedIn: 'root'
})
export class ComplianceService {

  complianceAgreeUrl: string = "/agreeCompliancePolicy";
  saveUserProfileUrl: string = "/saveUserProfile";
  saveCMDUserProfileUrl: string = "/cmd/updateUserProfile";
  languageListUrl:string = "./assets/languages.json";
  loginCache;
  httpOptions;
  constructor(public http: HttpClient, public sharedService: SharedService, public tokenStorage:TokenStorageService) {
  }

  complianceAgree() {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
      this.httpOptions = {
        headers: new HttpHeaders({
          'communityId':this.loginCache.communityId[0].toString(),
          'userId': this.loginCache.userId.toString(),
          'Content-Type': 'application/json'
  
        })
      }
    let _url = this.sharedService._hostUrl + this.complianceAgreeUrl;
    return this.http.post(_url, null, this.httpOptions);
  }

  saveUserProfile(body) {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    let headers = new HttpHeaders({
      'communityId': this.loginCache.communityId[0].toString(),
      'userId': this.loginCache.userId.toString()
    })
    let _url = this.sharedService._hostUrl + this.saveUserProfileUrl;
    return this.http.post(_url, body, { headers, responseType: 'text' });
  }

  getLanguage(){
    return this.http.get(this.languageListUrl)
  }
}
