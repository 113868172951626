import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SharedService} from "../shared/shared.service";
import {Injectable} from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class LandingService {
  httpHeader;

  constructor(public httpClient: HttpClient, public sharedService: SharedService) {}
  getAllProducts() {
    this.httpHeader = new HttpHeaders({
      'pin': "",
      'searchKey': "",
      'userLocale': "",
    });
    //NESOSA-2067
    /*const body: FormData = new FormData();
    body.append('searchKey', "");*/

    let url = this.sharedService._hostUrl + "/landing/searchProducts";
    return this.httpClient.get(url,  { headers: this.httpHeader, responseType: 'json' });
  }
}
