import { LoaderService } from './../../shared/loader.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { LoginService } from 'src/app/auth/login/login.service';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { HomeService } from 'src/app/shared/home.service';
import { saveAs } from 'file-saver';
import { SharedService } from 'src/app/shared/shared.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  url: string;
  display = 'none';
  loginCache;
  userName;
  lastName;
  firsttime: boolean = false;
  lastLoginTime;
  showDropDown: boolean = false;
  openBreadcrumbs: boolean = false;
  showMenuScreen: boolean = false;
  showDocumentation = false;
  viewProfileContent: boolean = false;
  public isDashboard: boolean = false;
  appVersion;
  nestradeLang:boolean = false;
  @Output() headerMenuEventEmitter = new EventEmitter();
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public sharedService: SharedService, public route: Router,
    public tokenStorage: TokenStorageService,
    public translateService: TranslateService,
    public loginService: LoginService, public homeService :HomeService,
    public loader:LoaderService) { }

  ngOnInit() {
    this.loginService.appVersion().subscribe(data => {
      this.appVersion = JSON.parse(data);
    })
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
   /*  if(this.loginCache.userLocale == 'xx'){
      this.nestradeLang = true;
    } */
    //this.sharedService.isDemoCommunity = this.loginCache.communityName[0].demo;
  }

  ngAfterViewInit() {


  }

  viewProfile() {
    this.url = "/menu/viewProfile";
    this.headerMenuEventEmitter.emit({ url: this.url });
  }

  changePassword() {
      this.url = "/changePassword";
      this.headerMenuEventEmitter.emit({ url: this.url });
  }

  showMenu(menuToggle) {
    //this.sharedService.showmenu = !menuToggle;
    //this.showMenuScreen = this.sharedService.showmenu;
  }

  gotoHome() {
    this.route.navigate(['/home']);
    this.sharedService.breadCrumbsArray = [];
  }

  settings() {
    this.url = "/menu/settings";
    this.headerMenuEventEmitter.emit({ url: this.url });
  }

  showHelp() {
    this.url = "/menu/help";
    this.headerMenuEventEmitter.emit({ url: this.url });
    // this.sharedService.showProfileContent=false;
  }



  logMeOut() {
    this.loginService.logout().subscribe(data => {
      if (data['logoutSuccess'])
        this.url = "/login";
        // this.route.navigate(['/login']);
        this.headerMenuEventEmitter.emit({ url: this.url });
    });
    /* this.sharedService.breadCrumbsArray = [];
    this.sharedService.searchText = null;
    this.sharedService.noOfProductsAddedToCart = 0; */
  }

  getHelpDoc() {
    let fileName = "Ghaama-Help.pdf";
    this.homeService.getHelpDoc().subscribe(data => {
      if (data) {
        saveAs(data, fileName);
      }
    },
      err => {
        alert("Problem while downloading the file.");
      })
  }




}


