
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import * as $ from 'jquery';
// import 'datatables.net';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { LoaderService } from 'src/app/shared/loader.service';
import { SharedService } from '../shared.service';
import { DataTableService } from './data-table.service';

// import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})

export class DataTableComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @Input('rowData') rows;
  @Input('columnData') columns;
  @Input('limitOptions') limitOptions;
  @Input('page') page;
  @Input('ordersCount') ordersCount;
  // @Output() select = new EventEmitter();
  rowDataTemp;
  fetchAllData: boolean = true;
  totalCount;
  // @Input('fetchAllData') fetchAllData;
  selectedRow: any = [];
  previousSelectedRow: any = [];
  data_size: number;
  filter: boolean = false;
  searchText;
  startIndex;
  endIndex;
  searchVal;
  searchValue: string = null;
  footerMessage;
  searchFooterMessage;
  routePathInfo:string = '';

  // searchTextLength:number = 0;
  @Output() dataTableSelectedRowEmitter = new EventEmitter<boolean>();
  @Output() dataTablePageEmitter = new EventEmitter<boolean>();
  @ViewChild('ngxDatatableRef') ngxDatatableRef: DatatableComponent;


  highlight_danger: boolean = true;
  @Input() messages: any = {
    emptyMessage: this.sharedService.translate('order.list.page.order.notfound')

  }
  @Output() dataTableOffsetEmitter = new EventEmitter();
  @Output() dataTableSortEmitter = new EventEmitter();
  @Output() dataTableSearchEmitter = new EventEmitter();

  constructor(public dataTableService: DataTableService, public loader: LoaderService, public route: ActivatedRoute,
    public sharedService: SharedService, public translate: TranslateService, ) { }

  ngOnInit() {

    this.rowDataTemp = this.rows;
    //alert("rows : " +JSON.stringify(this.rows));
    this.routePathInfo = this.route.url['value'][0].path;
    this.data_size = this.rows.length;
    if ( this.routePathInfo === "orderList") {
      this.data_size = this.ordersCount;
      this.messages = { emptyMessage: '' };
    }
    var message = this.translate.instant('dataTable.fileterd');
    this.footerMessage = message.replace("{0}", this.data_size);


    //datatable filtered data retain
    if (this.route.url['value'][0].path === "homePage") {
      this.searchText = sessionStorage.getItem("searchHomePage");
    }
    else if (this.route.url['value'][0].path === "customerList") {
      let homePage = sessionStorage.getItem("searchHomePage");
      sessionStorage.removeItem("searchOderList");
      if (homePage != '') {
        this.searchText = sessionStorage.getItem("searchCustomerList");
      } else {
        sessionStorage.removeItem("searchCustomerList");
        sessionStorage.removeItem("searchOderDetail");
      }
    }
    else if (this.route.url['value'][0].path === "orderList") {
      let searchCust = sessionStorage.getItem("searchCustomerList");
      if (searchCust != '') {
        this.searchText = sessionStorage.getItem("searchOderList");
      }
    }
    else if (this.route.url['value'][0].path === "orderDetail") {
      let searchlist = sessionStorage.getItem("searchOderList");
      if (searchlist != '') {
        this.searchText = sessionStorage.getItem("searchOderDetail");
      }
    }
    else {
      this.searchText = sessionStorage.getItem("searchVal");
    }
    if (this.searchText && this.routePathInfo != "orderList") {
      this.fetchAllData = true;
      this.searchData();
      this.page.offset = 0;
      // this.filter = true;
      this.setPage(this.page, this.rows);
    } else {
      this.totalCount = this.page.totalCount;
      this.fetchAllData = this.page.fetchAllData;
      this.setPage(this.page, this.rows);
    }

    var searchResult = this.translate.instant('dataTable.footer')
    this.searchFooterMessage = searchResult.replace("{0}", this.startIndex + 1);
    this.searchFooterMessage = this.searchFooterMessage.replace("{1}", this.endIndex + 1);
    if ( this.routePathInfo === "orderList") {
      this.searchFooterMessage = this.searchFooterMessage.replace("{2}", this.ordersCount);

    }else{
    this.searchFooterMessage = this.searchFooterMessage.replace("{2}", this.data_size);
    }
  }

  ngAfterViewInit() {
    var sortingColumnItems = document.getElementsByClassName("sort-btn");
    if (sortingColumnItems.length > 0) {
      let i = 0;
      let classNames = ["sort-desc", "datatable-icon-down"];
      while (i <= sortingColumnItems.length) {
        classNames.forEach(el => {
          if (sortingColumnItems.item(i) != null) {
            sortingColumnItems.item(i).classList.add(el);
          }
        })
        i++;
      }
    }
  }

  fetchData(pageInfo) {
    let routePath = this.route.url['value'][0].path;

    if (routePath === "orderList") {
      this.page.sort=true;
      this.table.rows = [];

      this.dataTableOffsetEmitter.emit({'limit':this.page.limit,'offset':pageInfo.offset,'page':this.page});
    }
    if (!this.fetchAllData || !this.page.fetchAllData) {
      this.rows = [];
      this.columns = [];
      this.page.offset = pageInfo.offset;
      this.dataTablePageEmitter.emit(this.page);
      // if (pageInfo.fetchAllData)
      //   this.fetchAllData = true;


      // this.dataTableService.getData(pageInfo).subscribe(data => {
      //   this.rows = data['data'];
      //   this.page = data["page"];
      //   this.setPage(this.page, this.rows);
      // });
    }
    else {
      this.setPage(pageInfo, this.rows);
    }
  }

  refreshOnListChange(advanceSearch,offsetChange){
    if(advanceSearch && !offsetChange){
      this.table.offset= 0;
      this.page.offset = 0;
    }
   this.table.rows = [];
   this.page.totalCount = this.ordersCount;
   this.setPage(this.page, this.rows);
   //this.ngAfterViewInit();
  }

  setPage(page, rows) {

    this.page.offset = page.offset;

    //current pagination
    let currentPage = page.offset + 1;

    //number of items to be displays
    let limit = page.limit;

    //length of total data received
    let totalItems = this.page.totalCount;

    if (this.page.totalCount > 0) {
      //start index of the data to be displayed
      this.startIndex = (currentPage - 1) * limit;

      //end index of the data to be displayed
      this.endIndex = Math.min(this.startIndex + limit - 1, totalItems - 1);
    } else {
      this.startIndex = -1;
      this.endIndex = -1;
      // this.fetchAllData = false;
    }

    if (this.fetchAllData) {
      //load data based on start and end index
      this.loadData(this.startIndex, this.endIndex, rows);
    }
    else this.table.rows = rows;

  }


  loadData(startIndex, endIndex, rows) {
    //loading data into new array to be displayed from original data recived
    // if(rows != null){
    //let temp = rows.slice(startIndex, endIndex + 1);
    let temp;
    let routePath = this.route.url['value'][0].path;
    this.data_size = this.rows.length;
    if (routePath === "orderList") {
      temp = rows;
    }else{
      temp = rows.slice(startIndex, endIndex + 1)
    }
    this.table.rows = temp;
    //alert("temp : " + JSON.stringify(this.table.rows));
    // }
    // else{
    //   this.table.rows = []
    // }

    var searchResult = this.translate.instant('dataTable.footer')
    this.searchFooterMessage = searchResult.replace("{0}", this.startIndex + 1);
    this.searchFooterMessage = this.searchFooterMessage.replace("{1}", this.endIndex + 1);
    if (routePath === "orderList") {
      this.searchFooterMessage = this.searchFooterMessage.replace("{2}", this.ordersCount);
    }else{
    this.searchFooterMessage = this.searchFooterMessage.replace("{2}", rows.length);
    }
    if (routePath === "orderList") {
      if(this.table.rows.length == 0 ){
        this.messages = {
          emptyMessage: this.sharedService.translate('order.list.page.order.notfound')
        }
    }
    }
  }

  onLimitChange(limit) {
    this.page.limit = parseInt(limit);
    this.page.offset = 0;
    this.page.count = 0;
    this.page.totalCount = 0;
    this.table.rows = [];
    this.dataTablePageEmitter.emit(this.page);

  }

  searchData() {

    if (this.routePathInfo === "orderList") {
      this.table.offset= 0;
      this.page.offset = 0;
      //if (this.searchText.length != 0){
      sessionStorage.setItem("searchOderList", this.searchText.trim());
      this.dataTableSearchEmitter.emit({'searchStr':this.searchText.trim().toLowerCase()});
   // }
    }else{

    this.filter = true;
    let temp = [];
    this.table.rows = [];

    this.searchValue = this.searchText.toLowerCase();
    if (this.searchValue.length == 0)
      this.filter = false
    let val1 = this.searchValue.trim();
    let routePath = this.route.url['value'][0].path;

    //set search filter value to session
    if (routePath === "homePage") {
      sessionStorage.setItem("searchHomePage", val1);
    }
    else if (routePath === "customerList") {
      sessionStorage.setItem("searchCustomerList", val1);
    }
    else if (routePath === "orderList") {
      sessionStorage.setItem("searchOderList", val1);
    }
    else if (routePath === "orderDetail") {
      sessionStorage.setItem("searchOderDetail", val1);
    }
    else {
      sessionStorage.setItem("searchVal", val1);
    }
    if (val1 != "" && val1 != undefined) {
      this.loader.display(true);
      if (this.page.fetchAllData) {
        let temprows = this.rowDataTemp;
        let search;
        temprows.forEach(row => {
          search = false;
          this.columns.forEach(column => {
            if (row[column.prop] && row[column.prop].toString().toLowerCase().includes(val1))
              search = true;
          });
          if (search) {
            temp.push(row);

          }
        });
        setTimeout(() => {
          this.page.totalCount = temp.length;
          this.rows = temp;
          this.page.offset = 0;
          // this.table.offset = 0
          this.setPage(this.page, this.rows);
          this.loader.display(false);
        }, 800);

        //set searchDataStore and searchPageCount filter value to session
      } else {
        this.dataTableService.getSearchData(val1).subscribe(data => {
          temp = data["data"];
          // this.page.totalCount = temp.length;
          this.rows = temp;
          this.page.offset = 0;
          if (!this.page.fetchAllData)
            this.fetchAllData = true;
          this.setPage(this.page, temp);
        });
      }
      this.rows = this.rowDataTemp;
      this.fetchAllData = true;
      this.page.totalCount = this.rows.length;
      this.fetchData(this.page);
      this.filter = true;

    } else {
      this.page.totalCount = this.rowDataTemp.length;
      this.rows = this.rowDataTemp;
      this.setPage(this.page, this.rows);
    }
  }
  }


  onSort(event) {

    const rows = this.rows;
    const sort = event.sorts[0];
    if( this.routePathInfo === "orderList"){
      this.table.offset= this.page.offset;
      this.page.count = 0;
      this.page.totalCount = 0;
      this.table.rows =[];
      this.dataTableSortEmitter.emit({'sort':sort,'page':this.page});
    }
    else{
    rows.sort((a, b) => {
      if (sort.prop == "lastLoginTime") {

        var t = a[sort.prop];
        var m = b[sort.prop];

        if (a[sort.prop] != undefined)
          a[sort.prop] = this.getDate(a[sort.prop]);


        if (b[sort.prop] != undefined)
          b[sort.prop] = this.getDate(b[sort.prop]);


        let n = 0;

        if ((a[sort.prop]) > b[sort.prop] || (a[sort.prop] == undefined && b[sort.prop] != undefined))
          n = -1;

        if (a[sort.prop] < b[sort.prop] || (a[sort.prop] != undefined && b[sort.prop] == undefined))
          n = 1;

        if ((a[sort.prop] == undefined && b[sort.prop] == undefined && sort.dir == 'asc') || (a[sort.prop] == b[sort.prop] && sort.dir == 'asc'))
          n = -1;

        if ((a[sort.prop] == undefined && b[sort.prop] == undefined && sort.dir == 'desc') || (a[sort.prop] == b[sort.prop] && sort.dir == 'desc'))
          n = 1;

        a[sort.prop] = t;
        b[sort.prop] = m;


        return n * (sort.dir === 'desc' ? -1 : 1);

      } else
        if (isNaN(a[sort.prop]))
          return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
        else {
          let n = 0;
          if ((a[sort.prop] - b[sort.prop]) > 0)
            n = 1
          else if ((a[sort.prop] - b[sort.prop]) < 0)
            n = -1;
          return n * (sort.dir === 'desc' ? -1 : 1)
        }
    });

      this.page.offset = 0;
      this.table.rows = rows;

  }
  }


  getDate(date: any): any {

    if (date != 'Invalid Date') {
      var dateParts = date.split("-");


      let dateArray: any;
      dateArray = dateParts.map((each) => {
        if (each == 'Jan')
          each = 1;
        if (each == 'Feb')
          each = 2;
        if (each == 'Mar')
          each = 3;
        if (each == 'Apr')
          each = 4;
        if (each == 'May')
          each = 5;
        if (each == 'Jun')
          each = 6;
        if (each == 'Jul')
          each = 7;
        if (each == 'Aug')
          each = 8;
        if (each == 'Sep')
          each = 9;
        if (each == 'Oct')
          each = 10;
        if (each == 'Nov')
          each = 11;
        if (each == 'Dec')
          each = 12;


        return Number(each)
      });
      var dateObject = new Date(dateArray[2], dateArray[1] + 1, dateArray[0]);
      return dateObject;
    }

  }



  onSelect(event) {
    this.selectedRow = event.selected

    /* start of single select */
    if (this.selectedRow.length == 1) {
      this.selectedRow.forEach(row => {
        this.previousSelectedRow.push(row);
      });
    }
    else if (this.selectedRow.length > 1) {
      if (this.selectedRow[0].userList)
        this.selectedRow = this.selectedRow.filter(item => item.userId !== this.previousSelectedRow[0]['userId']);
      else if (this.selectedRow[0].codeList)
        this.selectedRow = this.selectedRow.filter(item => item.codeId !== this.previousSelectedRow[0]['codeId']);
      else if (this.selectedRow[0].uomList)
        this.selectedRow = this.selectedRow.filter(item => item.uomId !== this.previousSelectedRow[0]['uomId']);
      else if (this.selectedRow[0].reasonList)
        this.selectedRow = this.selectedRow.filter(item => item.reasonCodeId !== this.previousSelectedRow[0]['reasonCodeId']);
      else if (this.selectedRow[0].profileList)
        this.selectedRow = this.selectedRow.filter(item => item.roleId !== this.previousSelectedRow[0]['roleId']);
      else if (this.selectedRow[0].productList)
        this.selectedRow = this.selectedRow.filter(item => item.productId !== this.previousSelectedRow[0]['productId']);
      else if(this.selectedRow[0].communityList)
        this.selectedRow = this.selectedRow.filter(item=>item.communityId !== this.previousSelectedRow[0]['communityId'])
      else if(this.selectedRow[0].salesOrgCodeList)
      this.selectedRow = this.selectedRow.filter(item =>item.salesOrgCodeId !== this.previousSelectedRow[0]['salesOrgCodeId'])
      this.previousSelectedRow = [];
      this.selectedRow.forEach(row => {
        this.previousSelectedRow.push(row);
      });
    }
    /*  end of single select */

    this.dataTableSelectedRowEmitter.emit(this.selectedRow);
  }

  getRowClass(row) {
    if (row.highlight_danger) {
      return {
        'highlight-danger': true
      }
    } else {
      if (row.odd) {
        return {
          'highlight-datatable-row-odd': true
        }
      }
    }
  }
}
