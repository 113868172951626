import { Component, Input, OnInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker/dist/interfaces';



@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit {
  public locale: string ;
  public placeholder: string = 'Select date';

  public time: Date = new Date();

  //currentYear: any = this.mytime.getUTCFullYear();
  //currentDate: any = this.mytime.getUTCDate()-1;
  //currentMonth: any = this.mytime.getUTCMonth() + 1; //months from 1-12
  currentDate: any = this.time.getDate() - 1;
  currentYear:any = this.time.getFullYear();
  currentMonth:any = this.time.getMonth()+1;

  @Input() language;
  public myDatePickerInlineOptions: IMyDpOptions = {
    inline: false,
    editableDateField: false,
    alignSelectorRight: false,
    disableUntil: { year: this.currentYear, month: this.currentMonth, day: this.currentDate },
    showWeekNumbers: false,
    openSelectorOnInputClick: true,    //If we enabled this attribute means we cannot edit the date field that means editableDateField always false
    dateFormat: 'dd.mm.yyyy',
    width:'150px'
 

  };

  constructor() { }

  ngOnInit() {

    this.onChangeLocale(this.language);
  }
  onChangeLocale(locale: string) {
    this.locale = locale;
  }

}
