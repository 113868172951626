import { IndividualConfig, ToastrService } from 'ngx-toastr';
// import {PlatformLocation } from '@angular/common';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import momentAR from 'node_modules/moment/locale/ar';
import momentDE from 'node_modules/moment/locale/de';
import momentES from 'node_modules/moment/locale/es';
import momentFR from 'node_modules/moment/locale/fr';
import momentIT from 'node_modules/moment/locale/it';
import momentJA from 'node_modules/moment/locale/ja';
import momentKO from 'node_modules/moment/locale/ko';
import momentNL from 'node_modules/moment/locale/nl';
import momentPL from 'node_modules/moment/locale/pl';
import momentPT from 'node_modules/moment/locale/pt';
import momentPTBR from 'node_modules/moment/locale/pt-br';
import momentRU from 'node_modules/moment/locale/ru';
import momentTH from 'node_modules/moment/locale/th';
import momentTR from 'node_modules/moment/locale/tr';
import momentVI from 'node_modules/moment/locale/vi';
import momentCN from 'node_modules/moment/locale/zh-cn';
import momentTW from 'node_modules/moment/locale/zh-tw';
import * as ExcelJS from "exceljs/dist/exceljs"
import * as FileSaver from 'file-saver';
import { BehaviorSubject } from 'rxjs';


declare const ExcelJS: any;

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  /* using for new home page */
  isPartyAdmin: boolean = false;
  isCustomer: boolean = false;
  isSupportAdmin: boolean = false;
  isAdmin: boolean = false;
  noOfProductsAddedToCart: number = 0;
  breadCrumbsArray: any = [];
  i: number = 0;
  searchText;
  showSearchBarAndCart: boolean = true;
  partyId;
  orderId;
  /* end for new home page */

  // showProfileContent:boolean = false;
  _hostUrl: string = environment.apiBaseUrl + "/rest";
  static _hostUrlExternalApi: string = environment.apiExternalApiUrl;
  //NMBS Roles
  isNmbs:boolean = false;
  isNMBSAdmin:boolean = false;
  isSupplierAdmin:boolean = false;
  isSupplierUser:boolean = false;
  isEDISupplier:boolean = false;
  pageType: string='';
  private refreshOrdListTableSrc = new BehaviorSubject<string>(this.pageType);
  refreshOrdListTableCurMsg = this.refreshOrdListTableSrc.asObservable();
  pipe;
  constructor(public translateService: TranslateService, private toastr:ToastrService) {
    let url = window.location.origin;
    this.pipe = new DatePipe('en-US');

  }
  translate(word): any {
    let value = "";
    if (word != null || word != "") {
      this.translateService.get(word).subscribe(v => {
        value = v;
      });
    } else {
      value = word;
    }
    return value;
  }
  addBreadcrumbs(key, currentUrl) {
    let index = this.breadCrumbsArray.findIndex(record => record.url === currentUrl);
    if (index == -1)
      this.breadCrumbsArray.push(
        {
          "key": key,
          "url": currentUrl
        }
      );
  }
  removeBreadcrumbs(currentUrl) {
    let index = this.breadCrumbsArray.findIndex(record => record.url === currentUrl) + 1;
    let noOfElemToRemove = this.breadCrumbsArray.length - (index);
    this.breadCrumbsArray.splice(index, noOfElemToRemove);

  }
  dateFormat(lastLoggedDate,isSeconds) {
    let localeStr: string = this.translateService.currentLang;
    switch (localeStr) {
      case "fr": {
        moment.locale('fr', momentFR);
        break;
      }
      case "de": {
        moment.locale('de', momentDE);
        break;
      }
      case "it": {
        moment.locale('it', momentIT);
        break;
      }
      case "nl": {
        moment.locale('nl', momentNL);
        break;
      }
      case "pl": {
        moment.locale('pl', momentPL);
        break;
      }
      case "pt": {
        moment.locale('pt', momentPT);
        break;
      }
      case "pt-BR": {
        moment.locale('pt-br', momentPTBR);
        break;
      }
      case "ru": {
        moment.locale('ru', momentRU);
        break;
      }
      case "tw": {
        moment.locale('zh-tw', momentTW);
        break;
      }
      case "es": {
        moment.locale('es', momentES);
        break;
      }
      case "cn": {
        moment.locale('zh-cn', momentCN);
        break;
      }
      case "tr": {
        moment.locale('tr', momentTR);
        break;
      }
      case "vi": {
        moment.locale('vi', momentVI);
        break;
      }
      case "ar": {
        moment.locale('ar', momentAR);
        break;
      }
      case "ko": {
        moment.locale('ko', momentKO);
        break;
      }
      case "ja": {
        moment.locale('ja', momentJA);
        break;
      }
      case "th": {
        moment.locale('th', momentTH);
        break;
      }
      default: {
        moment.locale('en');
        break;
      }
    }
    if(isSeconds){
    return moment(lastLoggedDate).format("DD-MMM-YYYY hh:mm A");
    }
    else{
    return moment.utc(lastLoggedDate).format("DD-MMM-YYYY");

    }

  }
  public  async exportAsExcelFile(data: any[], excelFileName: string, headersArray: any[],sheetName) {
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet(sheetName);
    //Add Header Row
    let headerRow = worksheet.addRow(headersArray);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '99CCFF' },
        bgColor: { argb: 'FF0000FF'}
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      cell.font ={bold : true}
    })
    // Add Data and Conditional Formatting
    data.forEach((element) => {
      let eachRow = [];
      headersArray.forEach((headers) => {
        eachRow.push(element[headers])
      })
      if (element.isDeleted === "Y") {
        let deletedRow = worksheet.addRow(eachRow);
        deletedRow.eachCell((cell) => {
          cell.font = { name: 'Calibri', family: 4, size: 11, bold: false, strike: true };
        })
      } else {
        worksheet.addRow(eachRow);
      }
    })
    let columnCount = worksheet.columnCount;
    let i=1;
    for( i = 1;i<columnCount+1;i++){
       worksheet.getColumn(i).width = 20;
    }
    worksheet.addRow([]);
    //Generate Excel File with given name
    await workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, excelFileName);
    });
  }


  showToastrInfoMsg(fileName) {
    this.toastr.info(this.translateService.instant('reports.filedownload.info'),fileName);
  }

  showToastrInfoMsgForUpload(fileName) {
    this.toastr.info(this.translateService.instant('reports.filedownload.info.continue'),fileName);
  }

  showToastrErrorMsg(msgKey: string ) {
    var options = {
      "positionClass": "toast-top-center"
    }
    this.toastr.error(msgKey,null,options);
  }

  refreshOrdListTableMsg(pageType : string) {
    this.refreshOrdListTableSrc.next(pageType)
  }

}
