import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { TokenStorageService } from 'src/app/shared/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  getcommunitiesListUrl: string = "/list";
  getCreateCommunityUrl: string = "/createCommunity";
  selectCommunityUrl:string ="/selectCommunities";
  updateUrl:string = "/updateCommunity";
  fetchRolesUrl: string = "/fetchRole";
  loginCache;
  httpHeader;

  constructor(public httpclient: HttpClient, public sharedService:SharedService , public tokenStorage: TokenStorageService) { }

  viewCommunitiesList(pageInfo) {
    let _url = this.sharedService._hostUrl + this.getcommunitiesListUrl;
    return this.httpclient.get(_url);
  }

  createCommunity(fileToUpload: File, body) {
    let _url = this.sharedService._hostUrl + this.getCreateCommunityUrl;
    const formData: FormData = new FormData();
    if(fileToUpload)
    formData.append('file', fileToUpload);
    formData.append('body', JSON.stringify(body));
    return this.httpclient.post(_url, formData);
  }

  updateCommunity(communityObj,fileToUpload: File ) {
    let url = this.sharedService._hostUrl + this.updateUrl;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('body', JSON.stringify(communityObj));
    return this.httpclient.post(url, formData);
  }
 
  saveRandomPassword(id,password){
    let headers = new HttpHeaders({
      'userId': id,
      'password':password
    });
    let url = this.sharedService._hostUrl+"/admin/user/savepassword";
    return this.httpclient.post(url,null, {headers:headers})
  }

  generateRandomPassword(id,admin,communityId){
    let httpHeaders = new HttpHeaders({
      'userId':id,
      'communityId':communityId
    })
    let serviceUrl = this.sharedService._hostUrl + "/admin/user/generatepassword/" + admin;
    return this.httpclient.get(serviceUrl,{headers:httpHeaders});
  }

  fetchRoles(){
    let url = this.sharedService._hostUrl + this.fetchRolesUrl;
    return this.httpclient.get(url);

  }
  fetchMigrationCommunity(){
    let url = this.sharedService._hostUrl + "/communities";
    return this.httpclient.get(url);
  }

}
