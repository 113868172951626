import { LoaderService } from './../../shared/loader.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { LoginService } from 'src/app/auth/login/login.service';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { HomeService } from 'src/app/shared/home.service';
import { saveAs } from 'file-saver';
import { SharedService } from 'src/app/shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { OrderService } from 'src/app/orders/order.service';
declare var Razorpay: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  url: string;
  display = 'none';
  loginCache;
  userName;
  lastName;
  firsttime: boolean = false;
  lastLoginTime;
  showDropDown: boolean = false;
  openBreadcrumbs: boolean = false;
  showMenuScreen: boolean = false;
  showDocumentation = false;
  viewProfileContent: boolean = false;
  public isDashboard: boolean = false;
  appVersion;
  nestradeLang:boolean = false;

  balance: number = 0;
  amount: number = 0;
  paymentLink: string = '';
  showPopup: boolean = false;
  walletAmountTobeAdded;
  walletAmount;
  isGlobalAdmin = false;

  @Output() headerMenuEventEmitter = new EventEmitter();
  constructor(
    public modalservice: NgxSmartModalService,
    //public ngxSmartModalService: NgxSmartModalService,
    public sharedService: SharedService, public route: Router,
    public tokenStorage: TokenStorageService,
    public translateService: TranslateService,
    public loginService: LoginService, public homeService :HomeService,
    public loader:LoaderService,private http: HttpClient,  public orderService: OrderService) { }

  ngOnInit() {
    this.loginService.appVersion().subscribe(data => {
      this.appVersion = JSON.parse(data);
    })
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    if(this.hasPermission('ROLE_PERMISSION_SELLER_CREATE_PARTY')){
      //this.nestradeLang = true;
      this.isGlobalAdmin = true;
      this.balance =  this.loginCache.wallet.balance;

    }
    //this.sharedService.isDemoCommunity = this.loginCache.communityName[0].demo;
  }

  ngAfterViewInit() {


  }
  hasPermission(permission: string): boolean {
    return this.loginCache.userPermission.includes(permission);
   }



  confirmPayment() {

    this.orderService.generateLink(this.loginCache.userId, this.walletAmountTobeAdded).subscribe( data => {
      this.loader.display(false);
       /* let response = {
        orderId: data['transactionId'],
        key: data['key'],
        amount: data['rozerPayObjectAmount'],
        currency: data['rozerPayObjectCurrency']
      }  */
      this.openTransactionModal(data);
      //this.router.navigate(['/orderdetails']);
    },
    error=>{
      console.error("error"+error);

    }
  );


  }

  openModal() {

      this.modalservice.getModal('wallet').toggle();
      this.modalservice.getModal('wallet').escapable = false;
      this.modalservice.getModal('wallet').dismissable = false;
      this.modalservice.getModal('wallet').closable = false;
      this.modalservice.getModal('wallet').hideDelay = 7;
      this.modalservice.getModal('wallet').open();

  }

  viewProfile() {
    this.url = "/menu/viewProfile";
    this.headerMenuEventEmitter.emit({ url: this.url });
  }

  changePassword() {
      this.url = "/changePassword";
      this.headerMenuEventEmitter.emit({ url: this.url });
  }

  showMenu(menuToggle) {
    //this.sharedService.showmenu = !menuToggle;
    //this.showMenuScreen = this.sharedService.showmenu;
  }

  gotoHome() {
    this.route.navigate(['/home']);
    this.sharedService.breadCrumbsArray = [];
  }

  settings() {
    this.url = "/menu/settings";
    this.headerMenuEventEmitter.emit({ url: this.url });
  }

  showHelp() {
    this.url = "/menu/help";
    this.headerMenuEventEmitter.emit({ url: this.url });
    // this.sharedService.showProfileContent=false;
  }



  logMeOut() {
    this.loginService.logout().subscribe(data => {
      if (data['logoutSuccess'])
        this.url = "/login";
        // this.route.navigate(['/login']);
        this.headerMenuEventEmitter.emit({ url: this.url });
    });
    /* this.sharedService.breadCrumbsArray = [];
    this.sharedService.searchText = null;
    this.sharedService.noOfProductsAddedToCart = 0; */
  }

  getHelpDoc() {
    let fileName = "Ghaama-Help.pdf";
    this.homeService.getHelpDoc().subscribe(data => {
      if (data) {
        saveAs(data, fileName);
      }
    },
      err => {
        alert("Problem while downloading the file.");
      })
  }

  closeModal(model){
    this.modalservice.getModal(model).close();
  }


  openTransactionModal(data: any) {
    let response = data;
    var options = {
      order_id: response['rozerPayObjectId'],
      key_id: response['key'],
      amount: response['rozerPayObjectAmount'],
      currency: response['rozerPayObjectCurrency'],
      name: 'seller.ghaama.com',
      description: "Wallet",
      image: 'assets/Ghaama%202-01.png',
      handler: (res: any) => {
        response.rozerPayPaymentId = res.razorpay_payment_id;
        response.rozerPaySignature = res.razorpay_signature;
        this.processResponse(response);

      },
      prefill : {
        name: 'GHAAMA',
        email: 'sales@ghaama.com',
        contact: ''
      },
      notes: {
        address : 'seller.ghaama.com'
      },
      theame: {
        color: '#f36e28'
      }

    };
    //new Razorpay(options);
    var razorpayObject = new Razorpay(options);
    razorpayObject.open();

  }

  parseKeyToJSON(key: string): any {
    try {
      // Parse the key string to JSON
      return JSON.parse(key);
    } catch (e) {
      console.error('Failed to parse key to JSON:', e);
      return null;
    }
  }

  consoleLog(key: string): any {
    try {
      // Parse the key string to JSON

    } catch (e) {
      console.error('Failed to parse key to JSON:', e);
      return null;
    }
  }

  processResponse(resp: any) {
  //  this.loader.display(true);
      this.loader.display(true);
      this.orderService.conformAddAmountTOWallet (resp).subscribe({
      //  this.loader.display(false);
        next: (data: any) => {
          this.loader.display(false);
          if (data) {
            //localStorage.removeItem('cartProducts');
            //this.sharedServices.addedProductList = [];
              if (JSON.parse(data) === "Success") {
                          //this.handleDeliveryPartnersData(responseData);
                          alert("Amount added successfully.");
                          //this.gotoHome();
                          this.closeModal("wallet");
              }

          }

      },
      error: () => {
       // this.loader.display(false);
       this.loader.display(false);
          alert("Something went wrong! Please try again later.");
      }

      })


  }

  getWallet() {
    //  this.loader.display(true);
        this.loader.display(true);
        this.orderService.getWallet().subscribe({
        //  this.loader.display(false);
          next: (data: any) => {
            this.loader.display(false);
            if (data) {
              this.walletAmount = data.balance;
              this.sharedService.walletAmount = data.balance;
              this.sharedService.walletId = data.id;
              //localStorage.removeItem('cartProducts');
              //this.sharedServices.addedProductList = [];
                /* if (JSON.parse(data) === "Success") {
                            //this.handleDeliveryPartnersData(responseData);
                            alert("Amount added successfully.");
                            this.gotoHome();
                } */

            }

        },
        error: () => {
         // this.loader.display(false);
         this.loader.display(false);
            //alert("Something went wrong f! Please try again later.");
        }

        })


    }


}


