import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';

import { CompliancePolicyComponent } from './auth/compliance-policy/compliance-policy.component';
import { LoginComponent } from './auth/login/login.component';
import { ProfileSetupComponent } from './auth/profile-setup/profile-setup.component';
import { RedirectComponent } from './auth/redirect/redirect.component';
import { SelectCommunityComponent } from './auth/select-community/select-community.component';
import { BaseLayoutComponent } from './core/base-layout/base-layout.component';
import { NewHomePageComponent } from './new-home-page/new-home-page.component';

import { OrderNotificationComponent } from './orders/order-list/order-notification/order-notification.component';
import { ConfigurationModule } from './configuration/configuration.module';
import { TerritoryModule } from './territory/territory.module';
import { UserModule } from './user/user.module';
import { CommunityModule } from './community/community.module';
import { MenuModule } from './menu/menu.module';
import { ProductsModule } from './products/products.module';
import { OrdersModule } from './orders/orders.module';
import { LandingPageComponent } from './landing/landing-page/landing-page.component';
import { SellerChargesComponent } from './auth/seller-charges/seller-charges.component';

const appRoutes: Routes = [
 // { path: '',  component: LandingPageComponent, pathMatch: 'full' },
 { path: '',  redirectTo: '/landingpage', pathMatch: 'full' },
 { path: 'SellerCharges', component: SellerChargesComponent },
  //{ path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'redirect', component: RedirectComponent },
  { path: 'login', component: LoginComponent },

  { path: 'landingpage', component: LandingPageComponent },

  { path: 'order-notification', component: OrderNotificationComponent },
  { path: 'compliancePolicy', component: CompliancePolicyComponent },
  { path: 'selectCommunity', component: SelectCommunityComponent },
  { path: 'home', component: BaseLayoutComponent, canActivate: [AuthGuard] },
  {
    path: '', component: CompliancePolicyComponent,
    children: [
      { path: 'changePasswordPage', component: ChangePasswordComponent },
      { path: 'profileSetup', component: ProfileSetupComponent }
    ]
  },

  {
    path: '', component: BaseLayoutComponent, children: [
      {
        path: 'config',
        loadChildren: './configuration/configuration.module#ConfigurationModule'
      },
      {
        path: 'territory',
        loadChildren: './territory/territory.module#TerritoryModule'
      },
      {
        path: 'user',
        loadChildren: './user/user.module#UserModule'
      },
      {
        path: 'community',
        loadChildren: './community/community.module#CommunityModule'
      },
      {
        path: 'menu',
        loadChildren: './menu/menu.module#MenuModule'
      },
      {
        path: 'products',
        loadChildren: './products/products.module#ProductsModule'
      },
      {
        path: 'ghaama',
        loadChildren: './ghaama/ghaama.module#GhaamaModule'
      },


      {
        path: 'orders',
        loadChildren: './orders/orders.module#OrdersModule'
      },
      { path: 'newHomePage', component: NewHomePageComponent },

      { path: 'changePassword', component: ChangePasswordComponent }

    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
