import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '../shared/token-storage.service';
import { SharedService } from '../shared/shared.service';



@Injectable({
  providedIn: 'root'
})
export class TerritoryManagmentService {
  fetchAllPartyListUrl: string = "/fetchAllParty";
  getParentBusinessUnitsList: string = "/admin/territory/init";
  getEndPointsBulist: string = "/admin/territory/bulist";
  createWareHoseAddressUrl = "/createParty";
  getEditTerritoryUrl: string = "/admin/territory/edit";
  deleteWareHouseAddressUrl: string = "/deleteParty";
  downloadTemplateUrl: string = "/admin/territory/downloadTemplate";
  uploadTemplateUrl: string = "/admin/territory/upload";
  getExport: string = "/admin/territory/exportExcel"
  loginCache;
  httpHeader;
  constructor(public httpClient: HttpClient, public sharedService: SharedService,public tokenStorage: TokenStorageService) {
  }

  fetchAllPartyList(pageInfo) {
    let _url = this.sharedService._hostUrl + this.fetchAllPartyListUrl;
    let headers = new HttpHeaders({
      'pageNo': pageInfo
    })
    return this.httpClient.get(_url,{headers:headers});
  }
  loadParentBusinessUnits() {
    let _url = this.sharedService._hostUrl + this.getParentBusinessUnitsList;
    return this.httpClient.get(_url, { headers: this.httpHeader });
  }
  loadBu(body) {
    let _url = this.sharedService._hostUrl + this.getEndPointsBulist;
    return this.httpClient.post(_url, body, { headers: this.httpHeader });
  }

  excelUpload(formData) {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    let _url = this.sharedService._hostUrl + this.uploadTemplateUrl;
    let headers = new HttpHeaders({
      'communityId': this.loginCache.communityId[0].toString(),
      'userId': this.loginCache.userId.toString()
    })
    return this.httpClient.post(_url, formData, { responseType: "blob",  headers: headers });
  }

  saveWareHoseAddress(body) {
    this.httpHeader = new HttpHeaders({'Content-Type': 'application/json'});
    let _url = this.sharedService._hostUrl + this.createWareHoseAddressUrl;
    return this.httpClient.post(_url, body, { headers: this.httpHeader });
  }

  getTerritoryDetails(body) {
    let _url = this.sharedService._hostUrl + this.getEditTerritoryUrl;
    return this.httpClient.post(_url, body, { headers: this.httpHeader });
  }

  deleteWareHouseAddress(body) {
    let _url = this.sharedService._hostUrl + this.deleteWareHouseAddressUrl;
    return this.httpClient.post(_url, body);
  }

  downloadExcelTemplate(file) {
    let _url = this.sharedService._hostUrl + this.downloadTemplateUrl;
    let body = { filename: file };
    return this.httpClient.post(_url, body, { responseType: "blob", headers: this.httpHeader });

  }
  getTerrotoryExport() {
    let _url = this.sharedService._hostUrl + this.getExport;
    return this.httpClient.get(_url, { headers: this.httpHeader });

  }
}
