import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SharedService } from 'src/app/shared/shared.service';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { UserService } from 'src/app/user/user-list/user.service';
import { HomeService } from '../../shared/home.service';
import { CustomerService } from 'src/app/orders/customer-list/customer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.css']
})
export class BaseLayoutComponent implements OnInit {

  pgName: string;

  constructor(public homeService: HomeService,
    public customerService: CustomerService,
    public sharedService: SharedService,
    public router: Router,
    public userService: UserService,
    public translate: TranslateService,
    public tokenStorage: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService) {
    translate.setDefaultLang('en');
    // this.source = new LocalDataSource();
  }

  display = 'none';
  @Output() demoEventEmitter = new EventEmitter();
  url: string;
  showDocumentation = false;
  html;
  FirstTimeLogin
  userName;
  loginCache;
  homeCache;
  showLayout: boolean = false;
  permissions: any = [];
  communityImage: any = [];
  searchBarCheck: boolean = true
  userData;
  staticImage:boolean = false;
  customerList;
  selectedCustomer;
  customerCache: any = {};
  communityName;
  ngOnInit() {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    //this.userData = this.loginCache.userPermission;
    //let orderNotification = JSON.parse(sessionStorage.getItem("orderNotification"));


     // this.url = "/newDashboardPage";
      this.url = "/orders/customerList";

      this.router.navigate([this.url]);





  }

  ngAfterViewInit() {

  }



  onCloseHandled(event) {
    this.display = event;
  }

  getTimeZones(): void {
    this.homeService.getTimeZones()
      .subscribe(data => {
        sessionStorage.setItem("timeZones", JSON.stringify(data));
      })
  }

  viewHeaderSection(headerMenuEvent): void {
    if (headerMenuEvent.url) {
      this.url = headerMenuEvent.url;
      this.router.navigate([this.url]);
    }
    else if (headerMenuEvent.display)
      this.display = headerMenuEvent.display;
    this.showDocumentation = false;

    if (headerMenuEvent.url == "/changePassword") {
      sessionStorage.setItem("Url", JSON.stringify(headerMenuEvent.url));
    }
    if (headerMenuEvent.url == "/cmsChangePassword") {
      sessionStorage.setItem("cmsUrl", JSON.stringify(headerMenuEvent.url));
    }

  }
  viewCompliancePolicy(complianceevent): void {
    this.FirstTimeLogin = complianceevent;
    this.showDocumentation = false;
  }



  goToProductSearch() {
    this.url = "/products/productSearch";
    if (this.router.url == this.url)
      this.url = "/products/product-Search"
    this.router.navigate([this.url]);
  }
  searchProduct(value) {
    this.url = "/products/productSearch";
    if ((this.router.url == this.url || this.router.url == "/products/product-Search") && value.length == 0) {
      if (this.router.url == this.url)
        this.url = "/products/product-Search"
      this.router.navigate([this.url]);
    }
  }
  goToCreateOrder() {
    this.url = "/orders/createOrder";
    this.router.navigate([this.url]);
  }



  goToOrderList() {
    this.router.navigate(['/orders/orderList']);
  }
  profileInfo(event) {
    this.userName = event;
  }

  viewSection(sideMenuEvent): void {
    this.url = sideMenuEvent.url;
    this.router.navigate([this.url]);
  }



}
