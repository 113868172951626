import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//import { FusionChartsModule } from 'angular-fusioncharts';
import { TreeModule } from 'angular-tree-component';
import { MyDatePickerModule } from 'mydatepicker';
import { RatingModule } from 'ngx-rating';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { TreeviewModule } from 'ngx-treeview';
import { DataTableComponent } from './data-table/data-table.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ModalComponent } from './modal/modal.component';
import { SearchFilterPipe } from './search-filter.pipe';
import { FormatStringPipe } from './stringFormatPipe';
import { NgxPaginationModule } from 'ngx-pagination';


@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    TranslateModule,
    FormsModule,
    NgxSmartModalModule,
    //FusionChartsModule,
    NgSelectModule,
    TreeviewModule,
    TreeModule,
    MyDatePickerModule,
    RouterModule,
    MatTabsModule,
    RatingModule,
    NgxPaginationModule,
    ReactiveFormsModule

  ],
  declarations: [
    SearchFilterPipe,
    DataTableComponent,
    FormatStringPipe,
    DatepickerComponent,
    ModalComponent,

  ],
  exports: [DataTableComponent,NgxDatatableModule,TranslateModule,FormsModule,NgSelectModule,RatingModule,NgxPaginationModule,
    NgxSmartModalModule,TreeviewModule,TreeModule,MyDatePickerModule,DatepickerComponent,ModalComponent,RouterModule,MatTabsModule,
    ReactiveFormsModule]
})
export class SharedModule { }
