// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* export const environment = {
  production: false,
       apiBaseUrl: "http://localhost:8081/order-app",

       dashBoardCommunityId:1
}; */
export const firebaseConfig = {
  apiKey: "AIzaSyDf3Ui5Jd-3Ytl2jSUR9llRtD5l_JlYTXk",
  authDomain: "ghaama-6b9ed.firebaseapp.com",
  projectId: "ghaama-6b9ed",
  storageBucket: "ghaama-6b9ed.appspot.com",
  messagingSenderId: "363919311329",
  appId: "1:363919311329:web:318f8d54cbcb250d5152a0",
  measurementId: "G-HJQK7BPZWY"
};

export const environment = {
  apiBaseUrl: '/ghaama',
  apiExternalApiUrl: '/ghaama/external/rest',
  //apiBaseUrl: '/aggregator',
  production: false,
  dashBoardCommunityId:1
  //defaultTimeout: 600000
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDeleg ate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
