import { Component, EventEmitter, OnInit, Output, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { HomeService } from 'src/app/shared/home.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { SharedService } from 'src/app/shared/shared.service';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { LoginService } from './login.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit  {

  username: string;
  password: string;
  userId: string;
  email: string;
  display = 'none';
  htmltitle;
  forgotPassword: boolean = false;
  mailEntered;
  errorcode;
  @Output() compliance = new EventEmitter();
  loginError: boolean = false;
  hide = false;
  show: boolean = false;
  type = "password";
  loginCache;
  private orderNotification: boolean = false;
  permissions: any = [];
  REGEX_EMAIL_FORMAT_INPUT = "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$";
  REGEX_PHONE_FORMAT_INPUT = "/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm}";
  appVersion;
  environmentValue: boolean = false;
  playStore;
  appStore;
  userData;
  homeCache: any = {};
  loginPageLocal: string = "";
  defaultLanguage = "en";
  languageList = [];
  isLoginChecked:boolean= true;
  phoneNumber;
  countryCode;
  itiElement;
  auth;
  provider;
  isLogin:boolean= true;
  gstUserNameValue:string;
  gstNumberValue:string;
  inputConfirmPasswordValue:string;
  inputPasswordRegisterValue:string;
  display1 = 'none'; // Initially, the modal is hidden
  gstOtp;
  emailOtp;
  userMobile: string;
  userEmail: string;
  id;
 /*  user: SocialUser;
  loggedIn: boolean; */


  constructor(
    public router: Router,
    public homeService: HomeService,
    public loginService: LoginService,
    public tokenStorage: TokenStorageService,
    public translate: TranslateService,
    private activateRoute: ActivatedRoute,
    public sharedService: SharedService,
    private loader: LoaderService,
     public modalservice: NgxSmartModalService
    //private authService: SocialAuthService
  ) { }



  checkToggle(){
    if(this.isLoginChecked){
      $( ".iti" ).css( "display", "inline-block" );
    } else {
      $( ".iti" ).css( "display", "none" );
    }
  }

  ngAfterViewInit() {
    //Copy in all the js code from the script.js. Typescript will complain but it works just fine
   /*  this.itiElement = document.getElementsByClassName("iti iti--allow-dropdown iti--separate-dial-code");
    this.itiElement.hide(); */
    $( ".iti" ).css( "display", "none" );
 }

  ngOnInit() {

    this.loader.display(false);
    this.sharedService.searchText = null;
    this.sharedService.noOfProductsAddedToCart = 0;
    this.sharedService.isCustomer = false;
    this.sharedService.isSupportAdmin = false;

    this.loginService.getEnvironment().subscribe((data: string) => {
      if (JSON.parse(data) == 'PREP' || JSON.parse(data) == 'prep') {
        this.environmentValue = true;
      }
      if (this.environmentValue) {
        $("body").addClass("preprod-theme");
      }
      else {
        if ($("body").hasClass("preprod-theme")) {
          $("body").removeClass("preprod-theme");
        }
      }
    });

    this.loginService.appVersion().subscribe(data => {
      this.appVersion = JSON.parse(data);
    })
    /* this.loginService.getLanguageBundle(this.defaultLanguage).subscribe(data => {
      data['supportedLanguage'].forEach(element => {
        if (element['languageCode'] != 'xx') {
          let obj = {
            languageCode: element['languageCode'],
            primaryType: element['primaryType']
          }
          this.languageList.push(obj);
        }
      });
      if (this.loginService.loginCache != undefined) {
        if (this.loginService.loginCache.userLocale == 'xx') {
          this.loginPageLocal = ""
        } else {
          this.loginPageLocal = this.loginService.loginCache.userLocale;
        }
      }

      //this.appVersion = JSON.parse(data);
    }) */
    //We have placed this in help component
    /*  this.loginService.getPlayStoreLink().subscribe(data => {
       this.playStore = JSON.parse(data);
     })
     this.loginService.getAppStoreLink().subscribe(data => {
       this.appStore = JSON.parse(data);
     }) */
    let sub = this.activateRoute.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      if (params['from'] != null && params['from'] != '' && params['from'] != undefined && params['from'] == 'order-notification') {
        this.orderNotification = true;
        sessionStorage.setItem("orderNotification", "true");
      };
    });
    if (this.tokenStorage.getToken() !== null) {
      // this.loginService.logout().subscribe(data => {
      //   this.tokenStorage.signOut();
      // });
      this.tokenStorage.signOut();

    }
    this.htmltitle = "<div>" + this.sharedService.translate('reset.password.title') + "</div>";



  }




  openForgotPwd(): void {
    this.display = 'flex';
    this.forgotPassword = true;
  }
  onCloseHandled(event) {
    this.display = event;
  }

  toggleto() {
    this.isLoginChecked = true;
  }
  EnteredMail(event): void {
    this.mailEntered = event;
    this.loader.display(true);
    this.loginService.resetPassword(this.mailEntered).subscribe(data => {
      this.loader.display(false);
      if (data["successMessage"] == "Success") {
        alert(this.translate.instant('password.reset.successfull'));
      }
    },
      error => {
        this.loader.display(false);
        if (error.error.message != null) {
          alert(this.translate.instant(error.error.message));
        } else {
          alert("Something went wrong")
        }
      })
    this.display = 'none';
  }


  toggleShow() {
    this.show = !this.show;
    if (this.show) {
      this.type = "text";
      setTimeout(function () {
        this.type = "password";
      }.bind(this), 3000);
    }
    else {
      this.type = "password";
    }
  }



  userLogin(): void {
    this.loader.display(true);

     this.loginService.login(this.username, this.password).subscribe(data => {
      //save the token to session storage.
      this.loader.display(false);
      if (data["loginSuccess"] == true) {
        this.tokenStorage.saveToken(data["token"]);

        this.tokenStorage.saveLoginCache(JSON.stringify(data));
        this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
        //this.translate.use(this.loginCache['userLocale']);
        let userPermissions = this.loginCache.userPermission;

        if(this.loginCache.oneTimePwd || this.loginCache.passwordExpiry) {
          this.router.navigate(['/changePasswordPage']);
          return;
        }

        if(userPermissions.some(perm => perm === 'ROLE_PERMISSION_ADMIN_APPROVE_PRODUCT')) {
          let url = "/ghaama/viewProductApproval";
          this.router.navigate([url]);
          return;
        }

        if(userPermissions.length === 1 && userPermissions.some(perm => perm === 'ROLE_PERMISSION_SELLER_VIEW_ORDERS')) {
          this.sharedService.partyId = this.loginCache.partnerCode;
          this.router.navigate(['/orders/orderList']);
        } else {
          this.router.navigate(['/orders/customerList']);
        }

        /* let userPermissions = JSON.parse(this.tokenStorage.getLoginCache()).userPermission;
        if (userPermissions.some(perm => perm != 'ROLE_PERMISSION_SELLER_CREATE_PARTY') || userPermissions.some(perm => perm != 'ROLE_PERMISSION_ADMIN_APPROVE_PRODUCT')) {
          this.sharedService.isSupplierUser = true;
        }

        if(this.sharedService.isSupplierUser){


        } else {

        } */


       // this.sharedService.setPermissions(this.userData);

       // this.loadUserCommunities(); // to load different communities



      }
      else {
        this.errorcode = this.sharedService.translate(data["errorCode"]);
        this.loginError = true;
        alert("Bad credentials");
      }
    },
      (error) => {
        this.loginError = true;
        this.loader.display(false);
        if (error.error.message == "user.does.not.exist") {
          this.errorcode = this.sharedService.translate('AbstractUserDetailsAuthenticationProvider.badCredentials');
        } else {
          this.errorcode = "something went wrong"
        }
      }
    );

  }
  changeLanguage(data) {
    if (this.loginPageLocal != "" && this.loginPageLocal != undefined) {

      let body = {
        "userId": data['userId'],
        "userLocale": this.loginPageLocal
      }
      this.loginService.changeUserLanguage(body).subscribe(res => {
        if (res == "Success") {
          data['userLocale'] = this.loginPageLocal;
          this.tokenStorage.saveLoginCache(JSON.stringify(data));
          this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
          //this.translate.use(this.loginCache['userLocale']);
        }
      })
    }
  }
  login() {
    //this.territoryManagement(); // to check territorymanagement
    this.loginService.isLoggedIn = true;
    sessionStorage.setItem("compliancePageDetail", JSON.stringify({
      passwordExpiry: this.loginCache["passwordExpiry"],
      oneTimePwd: this.loginCache["oneTimePwd"],
      compliance: this.loginCache["compliance"],
      profileSetup: this.loginCache["profileSetup"],
      errorCode: this.loginCache["errorCode"],
      // isMigrated: this.loginCache["isUserMigrated"]

    }))
    // if (this.loginCache.isCMSUser) {
    if (this.loginCache["compliance"] == "YES" && !this.loginCache["passwordExpiry"] && !this.loginCache["oneTimePwd"]) {
      if (this.loginCache["profileSetup"]) {
        this.router.navigate(['/compliancePolicy'])
      }
      else {
        this.router.navigate(['/home']);
      }
    } else {
      this.router.navigate(['/compliancePolicy']);

    }



  }
  goToSellerCharges(){
    this.router.navigate(['/SellerCharges']);
  }

  openModal() {
    this.display1 = 'flex';
    this.modalservice.getModal('delete').toggle();
    this.modalservice.getModal('delete').escapable = false;
    this.modalservice.getModal('delete').dismissable = false;
    this.modalservice.getModal('delete').closable = false;
    this.modalservice.getModal('delete').hideDelay = 7;
    this.modalservice.getModal('delete').open();// Set display to true to show the modal
  }

  register() {
    if (!this.username || !this.inputConfirmPasswordValue || !this.userEmail || !this.gstUserNameValue
      || !this.gstNumberValue
    ) {
      alert("Please fill in all fields.");
      return;
    }

    this.loader.display(true);

    let body = {
      "userName": this.username,
      "mobileNo": this.userMobile,
      "email": this.userEmail,
      "gstUserName": this.gstUserNameValue,
      "gstin": this.gstNumberValue,
      "password": this.inputConfirmPasswordValue,
      "id": null,
      "gstOtp": null,
      "emailOtp": null,
      "mobileOtp": null
  }
    this.loginService.register(body).subscribe(
      data => {
        this.loader.display(false);

          this.id = data["id"];
          this.userId = data["userId"];
          this.openModal();
          // write here code to open popup app-modal defined on login.component.html of ghaamaangularui project

          //this.router.navigate(['/login']); // Redirect to login page after successful registration

      },
      error => {
        this.loader.display(false);
        if(error.error.errorCode == null){
          alert(error.error.message);
        } else {
          alert("An error occurred during registration.");
        }
      }
    );

  }

  verifyOtp() {
    if (!this.gstOtp || !this.emailOtp) {
      alert("Please fill in all fields.");
      return;
    }
    let body = {
      "userName": this.username,
      "mobileNo": this.userMobile,
      "email": this.userEmail,
      "gstUserName": this.gstUserNameValue,
      "gstin": this.gstNumberValue,
      "password": this.password,
      "userId": this.userId,
      "id": this.id,
      "gstOtp": this.gstOtp,
      "emailOtp": this.emailOtp,
      "mobileOtp": null
  }
  this.loader.display(true);
    this.loginService.verifyOtp(body).subscribe(
      data => {
        this.loader.display(false);
          alert("Registration successful. Please login to continue.");

         // this.openModal();
          // write here code to open popup app-modal defined on login.component.html of ghaamaangularui project

          this.router.navigate(['/login']); // Redirect to login page after successful registration

      },
      error => {
        this.loader.display(false);
        alert("An error occurred during registration.");
      }
  )}


  onChange(event) {
    if (event.target.value == "") {
      this.loginPageLocal = event.target.value;
      this.translate.use('en');
    } else {
      this.loginPageLocal = event.target.value;
      this.translate.use(event.target.value);
    }

  }

}
