import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../shared/token-storage.service';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  getPreferredLanguagesUrl: string = "./assets/languages.json";
  getTimeZonesUrl: string = "./assets/timeZones.json";
  nestleHomeUrl: string = "/getCommunityList";
  changeUserLocalUrl: string = "/changeUserLanguage";
  reportsUrl: string = "/reportsGraph";
  notificationUrl: string = "/notifications";
  usageInfoUrl: string = "/usageStatistics"
  loginData;
  httpHeader1;
  httpHeader;
  loginCache;

  constructor(public httpClient: HttpClient, public sharedService: SharedService, public tokenStorage: TokenStorageService) {
    this.httpHeader1 = new HttpHeaders({ 'content-type': 'application/json' });

  }

  getPreferredLanguages() {
    return this.httpClient.get(this.getPreferredLanguagesUrl);
  }
  getTimeZones() {
    return this.httpClient.get(this.getTimeZonesUrl);
  }
  nestleHome(body, token) {
    let _url = this.sharedService._hostUrl + this.nestleHomeUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': token
      })
    }
    return this.httpClient.post(_url, body, httpOptions);
  }
  changeUserLanguage(body): Observable<any> {
    let _url = this.sharedService._hostUrl + "/admin/user" + this.changeUserLocalUrl;
    return this.httpClient.post(_url, body);
  }

  getReports() {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());

    let body = {
      //NESTLE-R2-SECURITY CHANGES
      "userId": this.loginCache.userId.toString(),
      "buId": this.loginCache.buId,
      "communityId": this.loginCache.communityId.toString()
    }
    let _url = this.sharedService._hostUrl + this.reportsUrl;
    return this.httpClient.post(_url, body);
  }

  getNotification() {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    let body = {
      //NESTLE-R2-SECURITY CHANGES
      "buId": this.loginCache.buId,
      "communityIds": this.loginCache.communityId,
      "userId": this.loginCache.userId.toString()
    }
    let _url = this.sharedService._hostUrl + this.notificationUrl;
    return this.httpClient.post(_url, body);
  }

  getUsageInforamtion() {
    this.httpHeader = new HttpHeaders({ 'buId': this.loginCache.buId, 'communityId': this.loginCache.communityId.toString(), 'Content-Type': 'application/json' });
    let _url = this.sharedService._hostUrl + this.usageInfoUrl;
    return this.httpClient.post(_url, null, { headers: this.httpHeader })
  }

  isProductCatalogEnabled() {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    let body = {
      "buID": this.loginCache.buId.toString(),
      "userId": this.loginCache.userId.toString()
    }
    let _url = this.sharedService._hostUrl + "/enableProductCatalog";
    return this.httpClient.post(_url, body, { headers: this.httpHeader1 });

  }

  getFrequentlyOrderedProducts() {
    this.httpHeader = new HttpHeaders({
      //NESTLE-R2-SECURITY CHANGES
      'buId': this.loginCache.buId,
      'communityId': this.loginCache.communityId.toString(),
      'userId': this.loginCache.userId
    });
    let _url = this.sharedService._hostUrl + "/homeData";
    return this.httpClient.get(_url, { headers: this.httpHeader })
  }

  getNewProductsLaunch() {
    let _url = this.sharedService._hostUrl + "/customerCommunication/list";
    return this.httpClient.get(_url);
  }

  invoiceDownload(file) {
    /* let body = { filename: file};
    let _url = this.sharedService._hostUrl + "/usercsv";
    let a = "C:\orderapp-UI New3\OrderApplication\OrderApp-UI\src\assets\Fake_Invoice.pdf";
    return  this.httpClient.post(_url,body,{responseType: "blob"}); */
    return this.httpClient.get('./assets/Invoice.pdf', { responseType: "blob" });
  }

  csvDownload(file) {
    return this.httpClient.get('./assets/userDocument.csv', { responseType: "blob" });
  }
  getHelpDoc() {
    return this.httpClient.get('./assets/NMBS/NMBS.pdf', { responseType: "blob" });
  }

}
