import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '../../shared/token-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  getEmailUrl: string = "./assets/loginEmail.json";
  loginUrl: string;
  resetPasswordUrl: string = "/resetUserPassword"
  public isLoggedIn: boolean = false;
  territoryUrl: string = "/isTerritoryMgmtEnabled";
  loginCache;
  getAppVersion = "/version"
  constructor(public http: HttpClient, public sharedService: SharedService, public tokenStorage: TokenStorageService) { }

  appVersion() {
    let url = this.sharedService._hostUrl + this.getAppVersion;
    return this.http.get(url, { responseType: 'text' });
  }
  getEMail() {
    return this.http.get(this.getEmailUrl);
  }

  login(username, password) {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());

    var browser = (function (agent) {
      const deviceType: string = "Web - ";
      switch (true) {
        case agent.indexOf("edge") > -1: return deviceType + "edge";
        case agent.indexOf("opr") > -1: return deviceType + "opera";
        case agent.indexOf("chrome") > -1: return deviceType + "chrome";
        case agent.indexOf("trident") > -1: return deviceType + "ie";
        case agent.indexOf("firefox") > -1: return deviceType + "firefox";
        case agent.indexOf("safari") > -1: return deviceType + "safari";
        default: return "other";
      }
    })(window.navigator.userAgent.toLowerCase());

    let body = {
      'userName': username,
      'password': password,
      'notificationToken': "12272756",
      'deviceId': "1234",
      'device': browser

    }
    this.loginUrl = this.sharedService._hostUrl + "/loginSeller";
    return this.http.post(this.loginUrl, body);
  }

  resetPassword(username) {
    const parameters = { params: new HttpParams().set('userName', username) };
    let _url = this.sharedService._hostUrl + this.resetPasswordUrl;
    return this.http.post(_url, "", parameters);

  }

  isTerritoryEnabled(body) {
    let _url = this.sharedService._hostUrl + this.territoryUrl;
    return this.http.post(_url, body);

  }

  logout() {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    let body ={
      "userEmail": this.loginCache.userEmail
    }
    return this.http.post(this.sharedService._hostUrl + "/logout",body);
  }

  getLoggedInUserData() {
    return this.http.get(this.sharedService._hostUrl + "/loggedInUserData");
  }

  getEnvironment() {
    return this.http.get(this.sharedService._hostUrl + "/environment", { responseType: 'text' });
  }
  getPlayStoreLink() {
    return this.http.get(this.sharedService._hostUrl + "/playStoreLink", { responseType: 'text' });
  }
  getAppStoreLink() {
    return this.http.get(this.sharedService._hostUrl + "/appStoreLink", { responseType: 'text' });
  }
  fetchCommunicationDetails(communityId){
    let url = this.sharedService._hostUrl + "/customerCommunication/list/" + communityId;
    return this.http.get(url);
  }
  saveCommunicationDetails(body){
    let url = this.sharedService._hostUrl + "/customerCommunication/save";
    return this.http.post(url,body);
  }

  isSupportCreatedAdmin(body){
    let url = this.sharedService._hostUrl + "/admin/user/supportCreatedNmbs"
    return this.http.post(url,body);
  }

  changeUserLanguage(body) {
    const changeUserLocalUrl= "/changeUserLanguage";
    let _url = this.sharedService._hostUrl + "/admin/user" + changeUserLocalUrl;
    return this.http.post(_url, body);
  }

  /* getLanguageBundle(defaultLanguage: string) {
    let _url = this.sharedService._hostUrl + "/getLanguageBundles" ;
    let body ={
      "language": defaultLanguage
    }
    return this.http.post(_url, body);
  } */

}
