import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { TokenStorageService } from 'src/app/shared/token-storage.service';
import { SharedService } from 'src/app/shared/shared.service';
import { CommunityService } from 'src/app/community/community-support-admin/community.service';

@Component({
  selector: 'app-select-community',
  templateUrl: './select-community.component.html',
  styleUrls: ['./select-community.component.css']
})
export class SelectCommunityComponent implements OnInit {
  communities: any = [];
  communityId: any = [];
  res: any = [];
  loginCache;
  permissions: any = [];
  communityName;
  constructor(public communityservice: CommunityService, public activateRoute: ActivatedRoute, public loginService: LoginService,
    public tokenStorage: TokenStorageService, public router: Router , public sharedService: SharedService) {
  }

  ngOnInit() {
    this.communities = JSON.parse(this.tokenStorage.getLoginCache()).communityName;
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());

  }
  selectedCommunity(event) {
    this.loginCache['communityId'] = [JSON.parse(event.communityId)];
    this.communityName = event.communityName;
    this.tokenStorage.saveLoginCache(JSON.stringify(this.loginCache));
  }
  validateForm() {
    this.loginService.isLoggedIn = true;
    this.router.navigate(['/home']);
    sessionStorage.setItem("compliancePageDetail", JSON.stringify({ passwordExpiry: this.loginCache["passwordExpiry"], oneTimePwd: this.loginCache["oneTimePwd"], compliance: this.loginCache["compliance"], profileSetup: this.loginCache["profileSetup"], errorCode: this.loginCache["errorCode"] }))
    if (this.loginCache["compliance"] == "YES" && !this.loginCache["passwordExpiry"] && !this.loginCache["oneTimePwd"]) {
      if (this.loginCache["profileSetup"]) {
        this.router.navigate(['/compliancePolicy'])
      } 
    }
    else {
      this.router.navigate(['/compliancePolicy']);
    }

  }
}

