import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from '../shared/token-storage.service';
import { Observable } from 'rxjs';
import { PlaceOrderDTO } from './order.modal';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {



  loginCache;
  httpHeader;
  downloadUrl: string = "/admin/ordercreate/createOrderdownloadTemplate";
  uploadUrl: string = "/admin/ordercreate/upload";
  //downloadTemplateUrl: string = "/admin/ordercreate/createOrderdownloadTemplate";

  constructor(public httpClient: HttpClient, public sharedService: SharedService, public tokenStorage: TokenStorageService) {}
  getProducts(searchKey, customerId, communityId, userId, communityName,pageNumber) {

        this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
        const body: FormData = new FormData();
        body.append('searchKey', searchKey);
        body.append('communityName', communityName);
        body.append('customerId', customerId);
        body.append('communityId', communityId);
        body.append('userId', userId);
        body.append('userLocale', this.loginCache['userLocale']);
        body.append('position',pageNumber);
        let url = this.sharedService._hostUrl + "/admin/ordercreate/searchProductsPagination";
        return this.httpClient.post(url, body);

      }

  getDeliveryLocationList(customerId, communityId) {
    let url = this.sharedService._hostUrl + "/admin/ordercreate/chooseDelivery";
    this.httpHeader = new HttpHeaders({
      'customerId': customerId,
      'communityId': communityId
    });
    return this.httpClient.post(url, null, { headers: this.httpHeader, responseType: 'json' });
  }


  confirmOrder(body) {
    let url = this.sharedService._hostUrl + "/admin/ordercreate/orderConfirmFinal";
    return this.httpClient.post(url, body, { headers: new HttpHeaders(), responseType: 'text' });
  }
  editOrder(body) {
    let url = this.sharedService._hostUrl + "/admin/ordercreate/editOrder";
    return this.httpClient.post(url, body, { headers: new HttpHeaders(), responseType: 'text' });
  }
  fetchPartyOrders(currentPage, partyId) {
    this.httpHeader = new HttpHeaders({
      'currentPage' : currentPage,
      'partyId': partyId
    });
    let url = this.sharedService._hostUrl + "/admin/ordercreate/fetchPartyOrders";
    return this.httpClient.get(url, { headers: this.httpHeader, responseType: 'text' });
  }

  downloadExcel(body): Observable<any> {
    //var body = { "customerBuId": customerBuId};
    let url = this.sharedService._hostUrl + this.downloadUrl;
    return this.httpClient.post(url, body, { responseType: "blob", headers: this.httpHeader });
  }

  uploadExcel(fileToUpload: File, placeOrderDTO: PlaceOrderDTO) {
    const url = this.sharedService._hostUrl + this.uploadUrl;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('placeOrderData', JSON.stringify(placeOrderDTO));
    return this.httpClient.post(url, formData, { responseType: "blob", headers: this.httpHeader });
  }

  getAddItemProducts(searchKey, salesOrg, customerId, communityId, userId, userLocale, communityName) {

    this.httpHeader = new HttpHeaders({
      'customerId': customerId,
      'salesOrg': salesOrg,
      'communityId': communityId,
      //'searchKey': searchKey,
      'userId': userId,
      'userLocale': userLocale,
    });
    //NESOSA-2067
    const body: FormData = new FormData();
    body.append('searchKey', searchKey);
    body.append('communityName', communityName);

    let url = this.sharedService._hostUrl + "/additems/searchProducts";
    return this.httpClient.post(url, body, { headers: this.httpHeader, responseType: 'json' });
  }

  fetchFailureOrders(body){
    const url: string = this.sharedService._hostUrl + '/order/fetchFailureOrders';
    return this.httpClient.post(url,body);
  }

  reprocessNestleOrders(list){
    let url = this.sharedService._hostUrl + '/reprocessNestleOrders';
    return this.httpClient.post(url,list);
  }

  fetchPricePromotion(vmnList: any[], customerId) {
    this.httpHeader = new HttpHeaders({
      'customerId': customerId
    });
    const obj = new Object({
      'vmnList': vmnList
    });
    let url = this.sharedService._hostUrl + '/admin/productcatalog/fetchPricePromotion';
    return this.httpClient.post(url, obj, { headers: this.httpHeader, responseType: 'json' });
  }

  generateLink(userId: any, walletAmountTobeAdded: any) {

    this.httpHeader = new HttpHeaders({
      'userId' : userId,
      'amount': walletAmountTobeAdded+""
    });
    let url = this.sharedService._hostUrl + "/api/wallet/addbalance";
    return this.httpClient.get(url, { headers: this.httpHeader, responseType: 'json' });
  }

  conformAddAmountTOWallet(body) {
    let url = this.sharedService._hostUrl + "/api/wallet/payment-response";
    return this.httpClient.post(url, body, { headers: new HttpHeaders(), responseType: 'text' });
    /* this.httpHeader = new HttpHeaders({
      'userId' : userId,
      'amount': walletAmountTobeAdded
    });
    let url = this.sharedService._hostUrl + "/api/wallet/add-balance";
    return this.httpClient.get(url, { headers: this.httpHeader, responseType: 'text' }); */
  }

  getWallet() {
    this.httpHeader = new HttpHeaders({
    });
    let url = this.sharedService._hostUrl + "/api/wallet/getWallet";
    return this.httpClient.get(url, { headers: this.httpHeader, responseType: 'text' });
  }

  /* getExcelTemplate(file,communityId) {
    let _url = this.sharedService._hostUrl + this.downloadTemplateUrl + communityId;
    let body = { filename: file };
    return this.httpClient.post(_url, body, { responseType: "blob", headers: this.httpHeader });

  } */

}
