import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/auth/login/login.service';
import { NavigationEnd, Router } from '../../node_modules/@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoaderService } from './shared/loader.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private subscriptions = [];
  trackerId: String;
  accountId: String;
  languageCode: String;
  constructor(public router: Router, public translate: TranslateService, public authGuard: AuthGuard, private auth: LoginService,
    public spinner :NgxSpinnerService,public loaderService:LoaderService) {

    translate.setDefaultLang('en');
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }

    });
  }

  ngOnInit() {
    let sourceUrl = window.location.href;
    this.accountId = sourceUrl.split('/').pop();
    let index=sourceUrl.lastIndexOf('/');
    let uriString= sourceUrl.substring(0,index);
    this.trackerId = uriString.split('/').pop();


    let index1 = uriString.lastIndexOf('/');
    let uriString1= sourceUrl.substring(0,index1);
    this.languageCode = uriString1.split('/').pop();


    if (sourceUrl.indexOf("updatePassword") != -1) {
      this.router.navigate(["/updatePassword/"+this.languageCode+"/"+this.trackerId+"/"+this.accountId]);
    }
    else {
      this.router.navigate(["/login"]);
    }
  }


  switchLanguage(language: string) {
    this.translate.use(language);
  }
  parseURLParams(url) {
    var queryStart = url.indexOf("?") + 1,
      queryEnd = url.indexOf("#") + 1 || url.length + 1,
      query = url.slice(queryStart, queryEnd - 1),
      pairs = query.replace(/\+/g, " ").split("&"),
      parms = {}, i, n, v, nv;

    if (query === url || query === "") return;

    for (i = 0; i < pairs.length; i++) {
      nv = pairs[i].split("=", 2);
      n = decodeURIComponent(nv[0]);
      v = decodeURIComponent(nv[1]);

      if (!parms.hasOwnProperty(n)) parms[n] = [];
      parms[n].push(nv.length === 2 ? v : null);
    }
    return parms;
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.loaderService.status.subscribe((val: boolean) => {
      if (val) {
       this.spinner.show();
      } else {
        this.spinner.hide();
      }
    }));
  }


  ngOnDestroy() {
    this.subscriptions.forEach(each => {
      each.unsubscribe();
    });
  }
}
