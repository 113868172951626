import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from  '@angular/common/http';
import { SharedService } from '../../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  customerListUrl:string = "/getCustomerList";
  httpHeader;
  constructor(public httpClient:HttpClient, public sharedService:SharedService) { 
  }

  getCustomerList(body,token) {
    let _url = this.sharedService._hostUrl+this.customerListUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': token
      })
    }
    return this.httpClient.post(_url, body, httpOptions);
  }
}
