import {Component, OnInit} from '@angular/core';
import  {LandingService} from "../landing-service";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit{
  constructor(
    public landingService: LandingService,

  ) { }
  ngOnInit() {


  }


  loadProducts(location) {
    //this.loader.display(true);
    this.landingService.getAllProducts().subscribe((data: any) => {
        if (data != null || data != undefined) {


        }
      },
      error => {
        //this.loader.display(false);
      }
    );
  }

}
