import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/auth/login/login.service';
import { TokenStorageService } from '../shared/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  permissions: any;
  constructor(private auth: LoginService, private myRoute: Router, public tokenStorage: TokenStorageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const publicRoutes = ['/SellerCharges', '/landingpage']; // Public routes
    if (publicRoutes.includes(state.url)) {
      return true; // Allow access without login
    }
    if (!this.auth.isLoggedIn) {
      this.myRoute.navigate(['/login']);
      return false;
    }
    return true;
  }
}
