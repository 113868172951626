import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { TokenStorageService } from '../shared/token-storage.service';
import { SharedService } from '../shared/shared.service';
import { HierarchyMessage } from './community-hierarchy.model';


@Injectable({
  providedIn: 'root'
})
export class CommunityHierarcyService {

  downloadCustomerMasterUrl: string = '/admin/customermaster/downloadTemplate';
  uploadCustomerMasterUrl: string = '/admin/customermaster/upload';
  downloadCustomerHierarchyUrl: string = '/admin/communityhierarchy/downloadTemplate';
  uploadCustomerHierarchyUrl: string = '/admin/communityhierarchy/upload';
  savePartyDeatilsUrl: string = '/createParty';
  uploadHierarchyUrl: string = '/admin/communityhierarchy/upload';
  hierarchy_DownloadUrl: string = '/admin/communityhierarchy/downloadTemplate';
  private hierarchyTreeUrl: string = '';
  httpHeader;
  httpHeader1;
  httpHeader2;
  loginCache;
  hierarchyMsg: HierarchyMessage = new HierarchyMessage();

  private hierachyMsgSrc = new BehaviorSubject<HierarchyMessage>(this.hierarchyMsg);
  hierachyCurrentMsg = this.hierachyMsgSrc.asObservable();

  constructor(public http: HttpClient, public sharedService: SharedService, public tokenStorage: TokenStorageService) {
  }

  uploadHierarchy(formData, communityId, userId) {
    const url = this.sharedService._hostUrl + this.uploadHierarchyUrl;
    this.httpHeader = new HttpHeaders({ 'communityId': communityId, 'userId': userId });
    return this.http.post(url, formData, { responseType: 'blob' , headers: this.httpHeader });
  }

  hierarchy_Download(file, body) {
    const url = this.sharedService._hostUrl + this.hierarchy_DownloadUrl;
    return this.http.post(url, body, { responseType: 'blob' });
  }

  downloadCustomerHierarchyExcel(file, communityId, userId) {
    const body = { filename: file };
    const url = this.sharedService._hostUrl + this.downloadCustomerHierarchyUrl;
    this.httpHeader = new HttpHeaders({ 'communityId': communityId, 'userId': userId });
    return this.http.post(url, body, { responseType: 'blob', headers: this.httpHeader });

  }

  uploadCustomerHierarchyExcel(formData, communityId, userId) {
    const url = this.sharedService._hostUrl + this.uploadCustomerHierarchyUrl;
    this.httpHeader = new HttpHeaders({ 'communityId': communityId, 'userId': userId });
    return this.http.post(url, formData, { responseType: 'blob', headers: this.httpHeader });
  }

  savePartyDetails(body): Observable<any> {
    const url = this.sharedService._hostUrl + this.savePartyDeatilsUrl;
    return this.http.post(url, body, { responseType: 'text' });
  }

  getPartyDetails(body) {
    const url = this.sharedService._hostUrl + '/showPartyInfo';
    return this.http.post(url, body);

  }
  getBuTree(body) {
    const url = this.sharedService._hostUrl + '/getCommunityTree';
    return this.http.post(url, body);
  }

  getBuData(body) {
    const getBuDataUrl = this.sharedService._hostUrl + '/viewBu';
    return this.http.post(getBuDataUrl, body);
  }

  getBusinessUnit(body) {
    const getBuDataUrl = this.sharedService._hostUrl + '/businessUnit/findOne';
    return this.http.get(getBuDataUrl, body);
  }

  getUserLevelNo(buId, userId): Observable<any> {
    const getUserLevelNoUrl = this.sharedService._hostUrl + '/getLevelNo';
    this.httpHeader1 = new HttpHeaders({ 'buId': buId, 'userId': userId });
    return this.http.get(getUserLevelNoUrl, { headers: this.httpHeader1 });
  }

  getLevelNo(communityId, selectedBuId): Observable<any> {
    const abcd = this.sharedService._hostUrl + '/getHubLevel';
    this.httpHeader2 = new HttpHeaders({ 'communityId': communityId, 'buId': selectedBuId });
    return this.http.get(abcd, { headers: this.httpHeader2 });
  }

  getLevelNoByBuId(selectedBuId): Observable<any> {
    const userhublevelUrl = this.sharedService._hostUrl + '/getLevelNo';
    this.httpHeader2 = new HttpHeaders({ 'buId': selectedBuId });
    return this.http.get(userhublevelUrl, { headers: this.httpHeader2 });
  }

  getCommunityHierarchyExport(body) {
    const url = this.sharedService._hostUrl + '/getCommunityHierachyExport';
    return this.http.post(url, body);
  }

  deleteSalesArea(body) {
    const url = this.sharedService._hostUrl + '/deleteSalesArea';
    return this.http.post(url, body);
  }

  getLoggedInUserBu(loggedInBuId) {
    let url = this.sharedService._hostUrl + '/getLoggedInUserBu';
    let header = new HttpHeaders({ 'buId': loggedInBuId });
    return this.http.get(url, { headers: header });
  }

  loadTree(body): Observable<any> {
    const url = this.sharedService._hostUrl + '/getLevelBasedTree';
    return this.http.post(url, body);
  }

  buSearch(body): Observable<any> {
    let url = this.sharedService._hostUrl + '/buSearch';
    return this.http.post(url, body);
  }

  getMarketForSelectedZone(id: any, communityIdValue: any) {
    let url = this.sharedService._hostUrl + '/getMarkets';
    let header = new HttpHeaders({ 'buId': id, 'communityId': communityIdValue });
    return this.http.get(url, { headers: header });
  }

  hierarchyMessage(hierarchyMsg: HierarchyMessage) {
    this.hierachyMsgSrc.next(hierarchyMsg)
  }

  loadTreeV1(body) {
    const url = this.sharedService._hostUrl + '/getBuTreeLite';
    return this.http.post(url, body);

  }

  loadHierarchyV2(selectedBuId) {
    const url = this.sharedService._hostUrl + '/getJSONBuTree';
    let httpHeader = new HttpHeaders({ 'buId': selectedBuId });
    return this.http.get(url, { headers: httpHeader });
  }

  deleteHierarchy(body) {
    const url = this.sharedService._hostUrl + '/deleteHierarchy';
    return this.http.post(url, body);
  }

  updateBuJSON(){
    const url = this.sharedService._hostUrl + '/fillMarketDataInBuHierarchy';
    return this.http.get(url);
  }

}