import { SharedService } from './../../shared/shared.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/shared/token-storage.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private tokenStorage: TokenStorageService, private router: Router, private route: ActivatedRoute,private sharedService:SharedService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let authReq = req;
      const currentUrl = this.router.url; // Get current route

      // Public routes that don't require authentication
      const publicRoutes = ['/SellerCharges', '/landingpage'];

      if (!publicRoutes.includes(currentUrl)) {
        if (authReq.url.includes("environment") || authReq.url.includes("version")) {
          authReq = req.clone({ setHeaders: { Authorization: "" } });
        } else {
          const token = this.tokenStorage.getToken();

          if (token) {
            authReq = req.clone({ setHeaders: { Authorization: token } });
          } else {
            // Redirect only if not on a public route
            if (!publicRoutes.includes(currentUrl)) {
              this.router.navigate(['/login']);
            }
            return next.handle(req); // Continue without auth header
          }
        }
      }

        return next.handle(authReq).do((event: HttpEvent<any>) => {
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                // executes only if error status code is 401 and 403
               // if (err.status === 401 || err.status === 403) {
                    // this.sharedService.showToastrErrorMsg('Session Expired.Please login to continue.');
                  //  this.router.navigate(['/login']);
                //} /* else if (err.status === 403) {
                   // this.router.navigate(['/login']);
                //} */
            } /* else if () {
                //page not found
            } */
        })

    }

}
