import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from '../../environments/environment';
import { LoginService } from '../auth/login/login.service';
import { BaseLayoutComponent } from '../core/base-layout/base-layout.component';
import { Product } from '../orders/order.modal';
import { HomeService } from '../shared/home.service';
import { SharedService } from '../shared/shared.service';
import { TokenStorageService } from '../shared/token-storage.service';
import { ProductService } from '../products/products-for-support-admin/product.service';


declare var jQuery: any;

@Component({
  selector: 'app-new-home-page',
  templateUrl: './new-home-page.component.html',
  styleUrls: ['./new-home-page.component.css']
})
export class NewHomePageComponent implements OnInit {
  viewTab;

  loginCache;
  userName;
  lastName;
  firstTimeLogin: boolean = false;
  lastLoginTime;
  homeCache: any = {};
  supplier: any = []
  reportMessage: boolean = false;
  notificationMessage: boolean = false;
  dataSource: Object;
  totalOrder2: Object;
  totalOrder3: Object;
  totalOrder4: Object;
  totalOrder5: Object;
  notificationData: any;
  totalCount: any = 0;
  supplierData: any = [];
  unreadCount: number = 0;
  highLight: boolean = false;
  permissions: any;
  orderdetailPage: boolean = false;
  statsticsData: any = [];
  userData: any = [];
  customerData: any = [];
  marketsData: any = [];
  ordersData: any = [];
  userSessionData: any = [];
  userSessionWebGraph: any = []
  customercache: any = {};
  templateError: boolean = false;
  usersDataForGraph: any = [];
  marketsDataArrayForGraph: any = [];
  customerDataForGraph: any = [];
  ordersDataForGraph: any = [];
  userSessionForGraph: any = [];
  userSessionWebDataForGrpah: any = [];
  infoMessage: boolean = false;
  unreadCheck: boolean = false;
  mostFrequentlyBoughtItems: any = [];
  newLaunchedProducts: any = [];
  reportSpinner: boolean = true;
  notificationSpinner: boolean = true;
  productSpinner: boolean = true;
  customerInformation: any = [];
  playStoreLink;
  appStoreLink;
  imageUrl: boolean = false;
  productsAvailable: boolean = false;
  addedProductList: Product[] = [];
  isNewBlockVisible: boolean = false;
  commArr;

  dashboardConfig: any[] = [];
  modalDashboardConfig: any[] = [];
  date;

  constructor(public homeService: HomeService,
    public translateService: TranslateService,
    public router: Router,
    public sharedService: SharedService,
    public baseLayout: BaseLayoutComponent,
    public tokenStorage: TokenStorageService,
    public loginService: LoginService,
    public modalservice: NgxSmartModalService,
    public httpClient: HttpClient,
    public productService:ProductService) {
  }

  ngOnInit() {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    this.isNewBlockVisible = (this.loginCache.communityId.indexOf(environment.dashBoardCommunityId) >= 0) ? false : true;
    this.date = this.sharedService.dateFormat(this.lastLoginTime, true);
    this.sharedService.showSearchBarAndCart = false;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.date = this.sharedService.dateFormat(this.lastLoginTime, true);

    });
    let community = this.loginCache.communityName[0];
    this.viewTab = 'tab1';
    /* if (this.sharedService.isAdminWithOrders || this.sharedService.isAdminWithoutOrders) {
      if (this.sharedService.isAdminWithOrders) {
        this.getNotifications();
        this.getWelcomePanelInfo();
        this.getInformation();
        if(this.sharedService.isDemoCommunity){
          this.reportSpinner = true;
          this.getReports();
        }
      }
      else {
        this.getWelcomePanelInfo();
        this.getNotifications();
        this.getInformation();
      }
    } else if (this.sharedService.isCustomer) {
      this.getReports();
      this.getNotifications();
      this.getFrequentlyOrderedProducts();
      if(community.demo){
      this.getProducts();
      }else{
        this.productsAvailable = true;
        this.productSpinner = false;
      }
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.infoMessage = true;
        this.getReports();
      });
    } */

    this.dashboardConfig = [{
      name: 'Total Orders',
      isVisible: true,
      id: 1
    }, {
      name: 'Orders at risk',
      isVisible: true,
      id: 2
    }, {
      name: 'Orders to be processed ',
      isVisible: false,
      id: 3
    }, {
      name: 'Open Exceptions',
      isVisible: true,
      id: 4
    }, {
      name: 'Delivery Status',
      isVisible: false,
      id: 5
    }, {
      name: 'Notification',
      isVisible: true,
      id: 6
    }, {
      name: 'Bulk File Download',
      isVisible: false,
      id: 7
    },{
      name: '',
      isVisible: true,
      id: 8
    }
  ];
    this.modalDashboardConfig = JSON.parse(JSON.stringify(this.dashboardConfig));
    this.configureCharts();
  }

  configureCharts() {
    this.totalOrder2 = {
      "chart": {
        "enableMultiSlicing": "1",
        "theme": "fusion"
      },
      "data": [{
        "label": "Some Risk",
        "value": "55.68"
      }, {
        "label": "High Risk",
        "value": "32.23"
      }, {
        "label": "Jeopardy",
        "value": "12.09"
      }]
    };
    this.totalOrder3 = {
      chart: {
        showSum: "1",
        theme: "fusion",
        "palettecolors": ["#29c3be", "#FFC533", "#f2726f"],
      },
      categories: [
        {
          category: [
            {
              label: "Order Count"
            }]
        }
      ],
      dataSet: [
        {
          seriesName: "< 1 Day",
          data: [
            {
              value: "105"
            }
          ]
        },
        {
          seriesName: "2-3 Days",
          data: [
            {
              value: "30"
            }
          ]
        },
        {
          seriesName: ">3 Days",
          data: [
            {
              value: "15"
            }
          ]
        }
      ]
    };
    this.totalOrder4 = {
      "chart": {
        "yaxismaxvalue": "10",
        "theme": "fusion",
      },
      "categories": [{
        "category": [{
          "label": "Hannah",
          "fontItalic": "0"
        }, {
          "label": "John",
          "fontItalic": "0"
        }, {
          "label": "Matt",
          "fontItalic": "0"
        }]
      }],
      "dataset": [{
        "seriesname": "Orders waiting for confirmation",
        "data": [{
          "value": "10",
          "allowDrag": "0"
        }, {
          "value": "7",
          "allowDrag": "0"
        }, {
          "value": "3",
          "allowDrag": "0"
        }]
      }, {
        "seriesname": "Orders being prepared",
        "data": [{
          "value": "7",
          "allowDrag": "0"
        }, {
          "value": "3",
          "allowDrag": "0"
        }, {
          "value": "2",
          "allowDrag": "0"
        }]
      }, {
        "seriesname": "Orders Ready for Shipment",
        "data": [{
          "value": "1",
          "allowDrag": "0"
        }, {
          "value": "3",
          "allowDrag": "0"
        }, {
          "value": "1",
          "allowDrag": "0"
        }]
      }]
    };
    this.totalOrder5 = {
      "chartConfig": {
        width: '350',
        height: '250',
        type: 'column2d',
        dataFormat: 'json',
      },
      "dataSource": {
        "chart": {
          "theme": "fusion",
          "palettecolors": ["#29c3be", "#FFC533", "#f2726f", "#5d62b5"],
        },
        "data": [{
          "label": "Delivery Created",
          "value": "10"
        }, {
          "label": "Batch Assigned",
          "value": "50"
        }, {
          "label": "Delivery Picked",
          "value": "25"
        }, {
          "label": "Delivery Shipped",
          "value": "50"
        }]
      }
    }
  }

  isVisible(id) {
    return this.dashboardConfig.find((each) => { return each.id === id }).isVisible;
  }

  updateConfig() {
    this.dashboardConfig = JSON.parse(JSON.stringify(this.modalDashboardConfig));
  }

  ngAfterViewInit() {
    this.getAppStore();
    this.getPlayStore();
  }

  loadFlexSlider() {
    jQuery('.flexslider').flexslider({
      animation: "slide",
      animationLoop: false,
      itemWidth: 150,
      itemMargin: 5,
      minItems: 2,
      maxItems: 6,
      controlsContainer: jQuery(".custom-controls-container"),
      customDirectionNav: jQuery(".custom-navigation a")
    });
  }
  loadFlexSlider1() {
    jQuery('.flexslider_id2').flexslider({
      animation: "slide",
      controlsContainer: jQuery(".custom-controls-container_id2"),
      customDirectionNav: jQuery(".custom-navigation_id2 a")
    });
  }

  getFrequentlyOrderedProducts() {
    this.productSpinner = true;
    this.homeService.getFrequentlyOrderedProducts().subscribe(data => {
      this.newLaunchedProducts = data["customerComm"];
      // this.commArr = this.newLaunchedProducts[0]['line3'].split(".")
      setTimeout(() => {
        this.loadFlexSlider1();
      }, 10);
    })
  }

  getProducts(){
    this.productSpinner = true;
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    const body: FormData = new FormData();
    body.append('isSupportAdmin', this.sharedService.isSupportAdmin.toString());
    body.append('communityId', this.loginCache.communityId[0].toString());
    body.append('buId', this.loginCache['buId']);
    body.append('userLocale', this.loginCache['userLocale']);
    this.productService.getProductsList(body).subscribe(data =>{
      this.mostFrequentlyBoughtItems = data;
        this.imageUrl = true;
      if(this.mostFrequentlyBoughtItems == null) {
        this.productsAvailable = true;
        this.productSpinner = false;
      }
      setTimeout(() => {
        this.loadFlexSlider();
        this.productSpinner = false;
      }, 1000);

    })

  }

  getAppStore() {
    this.loginService.getAppStoreLink().subscribe(data => {
      this.appStoreLink = JSON.parse(data);
    })
  }

  getPlayStore() {
    this.loginService.getPlayStoreLink().subscribe(data => {
      this.playStoreLink = JSON.parse(data);
    })
  }

  routeToSupplierList() {
    this.homeCache.communityId = this.loginCache.communityId;
    this.homeCache.communityName = this.loginCache.communityName[0].communityName;
    sessionStorage.setItem("homeCache", JSON.stringify(this.homeCache));
    let _url = "/orders/customerList";
    this.sharedService.addBreadcrumbs("companylist.page.title", _url);
    this.router.navigate([_url]);
  }


  goToOrderDetailPage(orderid, customerid, buId, community_Id, communityName, customerName) {
    this.sharedService.breadCrumbsArray = [];
    sessionStorage.setItem("orderId", orderid);
    this.customercache.customerId = customerid;
    this.customercache.buId = buId;
    this.customercache.customerName = customerName;
    sessionStorage.setItem("customerCache", JSON.stringify(this.customercache));
    this.orderdetailPage = true;
    sessionStorage.setItem("notificatin_communityId", community_Id);
    sessionStorage.setItem("notificatin_communityName", communityName);
    const _url = "/orders/orderDetail";
    this.sharedService.addBreadcrumbs("order.detail.page.title", _url);
    this.router.navigate([_url]);
  }


  getNotifications() {
    this.notificationSpinner = true;
    this.homeService.getNotification().subscribe(data => {
      this.notificationSpinner = false;
      this.notificationData = data;
      if ( this.notificationData.length == 0) {
        this.notificationMessage = true;
        this.notificationSpinner = false;
      }
    })
  }

  getWelcomePanelInfo() {
    this.loginCache = JSON.parse(this.tokenStorage.getLoginCache());
    this.loginService.getLoggedInUserData().subscribe(res => {
      sessionStorage.setItem("isTerritoryUser", res['territoryUser']);
      if (res) {
        this.userName = res["userFirstName"];
        this.lastName = res["userLastName"];
        this.lastLoginTime = res["lastLoginTime"];
      }
    });
  }


  getReports() {
    this.reportSpinner = true;
    const body = {
      'userEmail': this.loginCache['userEmail'],
      'isRolePresent': true
    };
    this.homeService.nestleHome(body, this.loginCache.token).subscribe(data => {
      if (data) {
        this.supplierData = data['communityList'];
        this.supplierData.forEach(element => {
          this.unreadCount = element['userCount'] ? element['userCount'] : 0;
          if (this.unreadCount > 0) {
            this.highLight = true;
          }
        });
      }
    })
    this.homeService.getReports().subscribe(results => {
      this.reportSpinner = false;
      if ((results["data"] != null) && (results["totalCount"])) {
        this.totalCount = results["totalCount"];
        if (this.totalCount) {
          this.unreadCheck = true;
        }
        results["data"].forEach((element,i) => {
          if(element.label == 'NEWOD' && element.value == 0){
            results["data"].splice(i,1);
          }
          switch (element.label) {
            case 'NEWOD': element.label = element.label = this.sharedService.translate("order.status.new.order"); break;
            case 'PENDG': element.label = element.label = this.sharedService.translate("order.status.pending"); break;
            case 'ACKLD': element.label = element.label = this.sharedService.translate("order.status.received"); break;
            case 'INPRC': element.label = element.label = this.sharedService.translate("order.status.inprocess"); break;
            case 'SHIPD': element.label = element.label = this.sharedService.translate("order.status.shipped"); break;
            case 'INVCD': element.label = element.label = this.sharedService.translate("order.status.invoiced"); break;
            case 'CANCL': element.label = element.label = this.sharedService.translate("order.status.cancelled"); break;
          }
        })

        let locale: string = this.translateService.currentLang;


        if (locale == "de" || locale == "it" || locale == "nl" || locale == "pt" || locale == "es" || locale == "tr" || locale == "vi" || locale == "pt-BR") {
          this.dataSource = {
            "chart": {
              "showValues": "1",
              "theme": "fusion",
              "formatNumberScale": "0",
              "placeValuesInside": "0",
              "labelDisplay": "rotate",
              "slantLabel": "1",
              "outCnvBaseFont": "Arial",
              "outCnvBaseFontSize": "10",
              "palettecolors": "#5d62b5",
              "thousandseparator": "."
            },
            "data": results["data"]
          };
        } else if (locale == "fr" || locale == "pl" || locale == "ru") {
          this.dataSource = {
            "chart": {
              "showValues": "1",
              "theme": "fusion",
              "formatNumberScale": "0",
              "placeValuesInside": "0",
              "labelDisplay": "rotate",
              "slantLabel": "1",
              "outCnvBaseFont": "Arial",
              "outCnvBaseFontSize": "10",
              "palettecolors": "#5d62b5",
              "thousandseparator": " "
            },
            "data": results["data"]
          };
        } else if (locale == "en" || locale == "tw" || locale == "cn" || locale == "ar" || locale == "ko" || locale == "ja") {
          this.dataSource = {
            "chart": {
              "showValues": "1",
              "theme": "fusion",
              "formatNumberScale": "0",
              "placeValuesInside": "0",
              "labelDisplay": "rotate",
              "slantLabel": "1",
              "outCnvBaseFont": "Arial",
              "outCnvBaseFontSize": "10",
              "palettecolors": "#5d62b5",
              "thousandseparator": ","
            },
            "data": results["data"]
          };
        }


      }
      else {
        this.reportMessage = true;
      }
      this.reportSpinner = false;
    });
  }



  getInformation() {
    this.homeService.getUsageInforamtion().subscribe(result => {
      this.statsticsData = result;
      if (this.statsticsData.length == 0) {
        this.infoMessage = true;
      }
      else {
        this.statsticsData.forEach(element => {
          this.usersDataForGraph.push({ 'label': element.reportDate, 'value': element.users });
          this.ordersDataForGraph.push({ 'label': element.reportDate, 'value': element.orders });
          this.marketsDataArrayForGraph.push({ 'label': element.reportDate, 'value': element.markets });
          this.customerDataForGraph.push({ 'label': element.reportDate, 'value': element.customers });
          this.userSessionForGraph.push({ 'label': element.reportDate, 'value': element.userSession });
          this.userSessionWebDataForGrpah.push({ 'label': element.reportDate, 'value': element.userSessionWeb });
        });

        this.userSessionWebGraph = {
          "chart": {
            "theme": "fusion",
            "lineThickness": "2",
            "showhovereffect": "1",
            "drawcrossline": "1",
            "divLineColor": "#6699cc",
            "divLineAlpha": "60",
            "divLineDashed": "0",
            "rotateLabels": "0",
            "labelStep": "2",
            "labelDisplay": "wrap"
          },
          "data": this.userSessionWebDataForGrpah
        }

        this.userData = {
          "chart": {
            "theme": "fusion",
            "lineThickness": "2",
            "showhovereffect": "1",
            "drawcrossline": "1",
            "divLineColor": "#6699cc",
            "divLineAlpha": "60",
            "divLineDashed": "0",
            "rotateLabels": "0",
            "labelStep": "2",
            "labelDisplay": "wrap"
          },
          "data": this.usersDataForGraph
        }

        this.customerData = {
          "chart": {
            "theme": "fusion",
            "lineThickness": "2",
            "showhovereffect": "1",
            "drawcrossline": "1",
            "divLineColor": "#6699cc",
            "divLineAlpha": "60",
            "divLineDashed": "0",
            "rotateLabels": "0",
            "labelStep": "2",
            "labelDisplay": "wrap"
          },
          "data": this.customerDataForGraph
        }


        this.ordersData = {
          "chart": {
            "theme": "fusion",
            "lineThickness": "2",
            "showhovereffect": "1",
            "drawcrossline": "1",
            "divLineColor": "#6699cc",
            "divLineAlpha": "60",
            "divLineDashed": "0",
            "rotateLabels": "0",
            "labelStep": "2",
            "labelDisplay": "wrap"
          },
          "data": this.ordersDataForGraph
        }


        this.marketsData = {
          "chart": {
            "theme": "fusion",
            "lineThickness": "2",
            "showhovereffect": "1",
            "drawcrossline": "1",
            "divLineColor": "#6699cc",
            "divLineAlpha": "60",
            "divLineDashed": "0",
            "rotateLabels": "0",
            "labelStep": "2",
            "labelDisplay": "wrap"
          },
          "data": this.marketsDataArrayForGraph
        }


        this.userSessionData = {
          "chart": {
            "theme": "fusion",
            "lineThickness": "2",
            "showhovereffect": "1",
            "drawcrossline": "1",
            "divLineColor": "#6699cc",
            "divLineAlpha": "60",
            "divLineDashed": "0",
            "rotateLabels": "0",
            "labelStep": "2",
            "labelDisplay": "wrap"
          },
          "data": this.userSessionForGraph
        }
      }
    })

  }

  downloadInformation() {

    let information = new Array();

    if (this.statsticsData.length == 0 || this.statsticsData == null || this.statsticsData == undefined) {
      this.modalservice.getModal('infoExport').toggle();
      this.modalservice.getModal('infoExport').escapable = false;
      this.modalservice.getModal('infoExport').dismissable = false;
      this.modalservice.getModal('infoExport').closable = false;
      this.modalservice.getModal('infoExport').hideDelay = 7;
      this.modalservice.getModal('infoExport').open();
    }
    else {
      for (let info of this.statsticsData) {
        let obj = new Object();
        obj["Year"] = info.reportDate
        obj["Users"] = info.users
        obj["Orders"] = info.orders;
        obj["Markets"] = info.markets;
        obj["Customers"] = info.customers;
        obj["UserSession"] = info.userSession;
        information.push(obj);
      }
      const header = ["Year", "Users", "Orders", "Markets", "Customers", "UserSession"];
      this.sharedService.exportAsExcelFile(information, 'Information.xlsx', header, 'information');
    }

  }


  localeNumberFormat(num) {
    try {
      if (num != "" || num != null || num != undefined) {
        let localeStr: string = this.translateService.currentLang;
        let formattedNumber: string = "";
        switch (localeStr) {
          case "fr": {
            formattedNumber = num.toLocaleString('fr');
            break;
          }
          case "de": {
            formattedNumber = num.toLocaleString('de');
            break;
          }
          case "it": {
            formattedNumber = num.toLocaleString('it');
            break;
          }
          case "nl": {
            formattedNumber = num.toLocaleString('nl');
            break;
          }
          case "pl": {
            formattedNumber = num.toLocaleString('pl');
            break;
          }
          case "pt": {
            formattedNumber = num.toLocaleString('pt');
            break;
          }
          case "ru": {
            formattedNumber = num.toLocaleString('ru');
            break;
          }

          case "tw": {
            formattedNumber = num.toLocaleString('zh-TW');
            break;
          }

          case "es": {
            formattedNumber = num.toLocaleString('es');
            break;
          }

          case "cn": {
            formattedNumber = num.toLocaleString('zh-CN');
            break;
          }

          case "tr": {
            formattedNumber = num.toLocaleString('tr');
            break;
          }
          case "vi": {
            formattedNumber = num.toLocaleString('vi-VN');
            break;
          }
          case "ko": {
            formattedNumber = num.toLocaleString('ko');
            break;
          }
          case "ja": {
            formattedNumber = num.toLocaleString('ja');
            break;
          }
          case "th": {
            formattedNumber = num.toLocaleString('th');
            break;
          }
          default: {
            formattedNumber = num.toLocaleString('en-US');
            break;
          }
        }
        return formattedNumber;
      }
    }

    catch (err) {


    }
  }
  addToCart(index) {

    if (!this.mostFrequentlyBoughtItems[index].finalQuantity)
      this.mostFrequentlyBoughtItems[index].finalQuantity = 0;

    this.mostFrequentlyBoughtItems[index].finalQuantity += 1;

    if (this.mostFrequentlyBoughtItems[index].finalQuantity == 1) {
      this.sharedService.noOfProductsAddedToCart += 1;
      this.addedProductList.push(this.mostFrequentlyBoughtItems[index]);
    }
    sessionStorage.setItem("addedProductListCache", JSON.stringify(this.addedProductList));

  }

  invoiceDownloadAction() {
    let fileName = "InvoiceDocument.pdf";
    this.homeService.invoiceDownload(fileName).subscribe(data => {
      if (data) {
        saveAs(data, fileName);
      }
    },
      err => {
        alert("Problem while downloading the file.");
      })
  }

  csvDownloadAction() {
    let fileName = "userDocument.csv";
    this.homeService.csvDownload(fileName).subscribe(data => {
      if (data) {
        saveAs(data, fileName);
      }
    },
      err => {
        alert("Problem while downloading the file.");
      })
  }




}
