import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-seller-charges',
  templateUrl: './seller-charges.component.html',
  styleUrls: ['./seller-charges.component.css']
})
export class SellerChargesComponent implements OnInit {

  sellerCharges: any = {};
  private jsonUrl = 'assets/Adjusted_Categories_Referral_Fee_Percentage.json';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getSellerCharges().subscribe(data => {
      this.sellerCharges = data.Categories;
    });
  }

  getSellerCharges(): Observable<any> {
    return this.http.get<any>(this.jsonUrl);
  }

  // Ensures all values are treated as an array, preventing the 'never' type error
 getReferralFees(fees: any): any[] {
  return Array.isArray(fees) ? fees : [{ "Item Price": "N/A", "Percentage": fees }];
}
}
