import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'searchfilter'
})
export class SearchFilterPipe  implements PipeTransform {
    transform(items: any[], value: string): any[] {  
        if (!items || items.length < 3) return [];  
        value = value.toLowerCase();
        
        if(value.length >= 3) {  
            //return items.filter(item => item.toLowerCase().indexOf(value) > -1);  
            return items.filter(function(item){
                return JSON.stringify(item).toLowerCase().includes(value);
            });
        }  
       /*  else  
        {  
            return items;  
        }   */
    }  
}